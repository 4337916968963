import { sha256 } from "js-sha256";
import MerkleTree from "merkletreejs";

import { whitelistCdnUrl } from "services/config";
import { EWhitelistedStatus } from "shared/interfaces";
import { uint8ArrayToHex } from "shared/utils";

export interface IUserWhitelistData {
  status: EWhitelistedStatus;
  usersCount: number | null;
  userIndex: number | null;
  proofs: Array<Array<number>> | null;
}

export const userWhitelistInitialData = {
  status: EWhitelistedStatus.UNKNOWN,
  usersCount: null,
  userIndex: null,
  proofs: null,
};

export const getUserWhitelistStatus = async (
  userId: string,
  whitelistHash: Array<number> | null,
  saleId: number
): Promise<IUserWhitelistData> => {
  if (!userId || !whitelistHash) return userWhitelistInitialData;

  try {
    const url = whitelistCdnUrl(saleId);
    const response = await fetch(url);
    const merkleLeaves: Array<string> = await response.json();
    const contractWhitelistHash = uint8ArrayToHex(whitelistHash);
    const usersCount = merkleLeaves.length;

    const merkleTree = new MerkleTree(merkleLeaves, sha256);
    const merkleTreeRootHash = merkleTree.getRoot().toString("hex");
    const proofs = merkleTree.getProof(sha256(userId)).map((proof) => Array.from(proof.data));

    const userIndex = merkleLeaves.findIndex((leaf) => leaf === sha256(userId));
    if (contractWhitelistHash === merkleTreeRootHash && userIndex > -1) {
      return { status: EWhitelistedStatus.WHITELISTED, usersCount, userIndex, proofs };
    }
    return { ...userWhitelistInitialData, status: EWhitelistedStatus.NOT_WHITELISTED };
  } catch (error) {
    console.error(`Error: while whitelist fetching from CDN \n ${error}`);
    return userWhitelistInitialData;
  }
};
