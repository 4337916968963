import styled from "styled-components";

import Buttons from "../Buttons";

const Container = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme, color }) => color || theme.lightOrange};
  color: ${({ theme }) => theme.black};
  border-radius: 1rem;
  margin-top: 1.5rem;
  padding: 1rem;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: 2rem;
`;

const UserInfo = styled.div`
  display: flex;
  max-width: 500px;
  width: 100%;
  justify-content: space-between;
  & > div:nth-child(2) {
    align-items: center;
  }
  & > div:last-child {
    align-items: flex-end;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 1.5rem;
    & > div:nth-child(2) {
      align-items: flex-end;
    }
    & > div:last-child {
      align-items: flex-start;
    }
  `}
`;

const UserInfoRow = styled.div`
  display: flex;
  flex-direction: column;
  & > p:first-child {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.938rem;
    color: ${({ theme }) => theme.primaryGrey};
    margin-bottom: 0.375rem;
  }
  & > p:last-child {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.188rem;
    color: ${({ theme }) => theme.black};
  }
`;

const Button = styled(Buttons.Primary)`
  width: 100%;
  max-width: 23.125rem;
  height: 3rem;
  margin-top: 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
  `}
`;

const VestingList = styled.div`
  width: 100%;
  max-width: 23.125rem;
  margin-top: 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
  `}
`;

const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  & > p {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.938rem;
    color: ${({ theme }) => theme.primaryGrey};
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  & > div:last-child {
    display: none;
  }
`;

const Row = styled.div<{ isEnded?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: 0.75rem;
  padding: 0 0.063rem;
  svg {
    margin-right: 0.75rem;
  }
  p {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.063rem;
    color: ${({ theme, isEnded }) => (isEnded ? theme.black : theme.lightGrey)};
  }
  & > p:last-child {
    flex: 1;
    text-align: right;
    font-weight: 400;
  }
`;

const Division = styled.div`
  margin: 0.75rem 0;
  height: 0.063rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.orangeDivision};
`;

const LockupButton = styled(Buttons.Primary)`
  background-color: ${({ theme }) => theme.blue};
  width: 100%;
  max-width: 23.125rem;
  height: 3rem;
  margin-top: 1rem;
  gap: 0.75rem;

  :hover {
    background-color: ${({ theme }) => theme.blueHover};
  }
  :active {
    background-color: ${({ theme }) => theme.blueActive};
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
  `}
`;

const MediumLink = styled.a`
  text-decoration: none;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  margin-top: 2rem;
  position: relative;
  border-radius: 1rem;
  color: ${({ theme }) => theme.black};
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 140%;
  svg {
    path {
      fill: ${({ theme }) => theme.black};
    }
  }
  ::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 1rem;
    padding: 2px;
    background: ${({ theme }) => theme.landingLinearGradient};
    mask: linear-gradient(${({ theme }) => theme.white} 0 0) content-box,
      linear-gradient(${({ theme }) => theme.white} 0 0);
    mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
  `}
`;

export default {
  Container,
  Title,
  UserInfo,
  UserInfoRow,
  Button,
  VestingList,
  HeaderList,
  List,
  Row,
  Division,
  LockupButton,
  MediumLink,
};
