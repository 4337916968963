import { ReactComponent as LockupIcon } from "assets/images/icons/lockup.svg";
import { ReactComponent as Medium } from "assets/images/social-icons/medium-icon.svg";
import { ITranslationKeys } from "services/translation";
import Timer from "shared/components/Timer";
import Translate from "shared/components/Translate";
import { lockupURL, linkOptions, lockupGuideOnMediumURL } from "shared/constants";
import { ETypeVesting } from "shared/interfaces";
import { colors } from "shared/theme";
import { getVestingTitle } from "shared/utils";

import styles from "../../styles";

interface ILockupSectionProps {
  userTokensArray: {
    title: string;
    value: string;
  }[];
  rewardTicker: string;
  vestingEnded: boolean;
  timestamp: number;
  vestingType: ETypeVesting;
}

export default function LockupSection({
  userTokensArray,
  rewardTicker,
  vestingEnded,
  timestamp,
  vestingType,
}: ILockupSectionProps) {
  const openLockup = () => window.open(lockupURL, linkOptions.blank, linkOptions.noreferrer);
  return (
    <styles.Container color={colors.blueOp01}>
      <styles.Title>
        <Translate value="Sale.Vesting.Title" />
      </styles.Title>
      <styles.UserInfo>
        {userTokensArray.map((el) => (
          <styles.UserInfoRow key={el.title}>
            <p>
              <Translate value={el.title as ITranslationKeys} />
            </p>
            <p>
              <Translate
                value="Sale.PriceValue"
                interpolation={{
                  price: el.value,
                  currency: rewardTicker,
                }}
              />
            </p>
          </styles.UserInfoRow>
        ))}
      </styles.UserInfo>
      <styles.MediumLink href={lockupGuideOnMediumURL} target={linkOptions.blank} rel={linkOptions.noreferrer}>
        <Translate value="Action.ReadGuide" />
        <Medium />
      </styles.MediumLink>
      <styles.LockupButton onClick={openLockup}>
        <Translate value="Action.ClaimAtLockup" />
        <LockupIcon />
      </styles.LockupButton>
      {!vestingEnded && <Timer title={getVestingTitle(vestingType)} time={timestamp} hideBackground />}
    </styles.Container>
  );
}
