import styled from "styled-components";

import { EStatus } from "shared/interfaces";

const StatusWrapper = styled.div<{ type?: EStatus }>`
  display: flex;
  align-items: center;
  padding: 0.281rem 0.375rem;
  border-radius: 4px;
  max-height: 1.5rem;
  background-color: ${({ theme, type }) => {
    if (type === EStatus.OPEN) return theme.statusOpenBg;
    if (type === EStatus.SOON) return theme.statusSoonBg;
    if (type === EStatus.CANCELLED) return theme.statusCanceledBg;
    return theme.statusEndedBg;
  }};

  color: ${({ theme, type }) => {
    if (type === EStatus.OPEN) return theme.statusOpenText;
    if (type === EStatus.SOON) return theme.statusSoonText;
    if (type === EStatus.CANCELLED) return theme.statusCanceledText;
    return theme.statusEndedText;
  }};
`;

const StatusText = styled.p`
  margin: 0;

  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.938rem;
`;

export default {
  StatusWrapper,
  StatusText,
};
