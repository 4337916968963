import useCountdown from "shared/hooks/useCountdown";

import styles from "./styles";
import Translate from "../Translate";

interface ITimestampModel {
  time: number;
}

export default function Timestamp({ time }: ITimestampModel) {
  const countdown = useCountdown(time);
  return (
    <styles.Time>
      <Translate
        value="Sale.TimestampDay"
        interpolation={{
          day: countdown.days,
        }}
      />
      <styles.Divider />
      <Translate
        value="Sale.Timestamp"
        interpolation={{
          hours: countdown.hours,
          minutes: countdown.minutes,
          seconds: countdown.seconds,
        }}
      />
    </styles.Time>
  );
}
