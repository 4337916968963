import { ReactComponent as Copy } from "assets/images/icons/copy.svg";
import modalStyles from "shared/components/Modals/Components/styles";
import ModalWrapper from "shared/components/Modals/ModalWrapper";
import shareStyles from "shared/components/ShareDropdown/styles";
import Translate from "shared/components/Translate";
import { linkOptions } from "shared/constants";
import { socialShare } from "shared/constants/social-share";
import copy from "shared/utils/copy";

export interface IShareModal {
  closeModal: () => void;
}

export default function ShareModal({ closeModal }: IShareModal): JSX.Element {
  const location = window.location.href;

  const shareHandler = (socialLink: string) => {
    window.open(socialLink, linkOptions.blank, linkOptions.noreferrer);
    closeModal();
  };

  const copyToClipboard = () => {
    copy(location);
    closeModal();
  };

  return (
    <ModalWrapper closeModal={closeModal}>
      <modalStyles.Header>
        <p>
          <Translate value="Share.Title" />
        </p>
        <modalStyles.Close onClick={closeModal}>
          <modalStyles.CloseIcon />
        </modalStyles.Close>
      </modalStyles.Header>
      <>
        {socialShare.map((social) => (
          <shareStyles.ShareButton key={social.name} onClick={() => shareHandler(social.link(location))}>
            {social.icon}
            {social.name}
          </shareStyles.ShareButton>
        ))}
      </>
      <shareStyles.ShareButton onClick={copyToClipboard}>
        <Copy />
        <Translate value="Action.CopyToClipboard" />
      </shareStyles.ShareButton>
    </ModalWrapper>
  );
}
