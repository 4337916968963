import { PropsWithChildren, useRef } from "react";

import { useOutsideClickCloseHandler } from "shared/hooks/useOutsideClickCloseHandler";

import styles from "./styles";

interface IOutsideClickWrapper extends PropsWithChildren {
  clickHandler(): void;
  isFullWidth?: boolean;
}

export function OutsideClickWrapper({ children, clickHandler, isFullWidth = false }: IOutsideClickWrapper) {
  const wrapperRef = useRef(null);
  useOutsideClickCloseHandler(wrapperRef, clickHandler);

  return (
    <styles.OutsideClickContainer isFullWidth={isFullWidth} ref={wrapperRef}>
      {children}
    </styles.OutsideClickContainer>
  );
}
