import { FungibleTokenContract, SaleContract } from "services/contract";
import { ISaleAccount } from "services/interfaces";
import { DEFAULT_PAGE_LIMIT } from "shared/constants";
import { ISale } from "shared/interfaces";
import {
  isNotNullOrUndefined,
  formatSale,
  toMap,
  formatUserData,
  getUserDataFilter,
  formatFutureSale,
} from "shared/utils";

import { getToken } from "./tokens";

function assertFulfilled<T>(item: PromiseSettledResult<T>): item is PromiseFulfilledResult<T> {
  return item.status === "fulfilled";
}

export async function retrieveSaleResult(pages: number, contract: SaleContract) {
  return (
    await Promise.allSettled(
      [...Array(pages)].map((_, i) => contract.getSales(i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT))
    )
  )
    .filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
}

export async function retrieveSale(contract: SaleContract) {
  const salesLength = await contract.getNumSales();
  const pages = Math.ceil(salesLength ? salesLength / DEFAULT_PAGE_LIMIT : 0);
  const salesResult = await retrieveSaleResult(pages, contract);
  const arr = salesResult.filter(isNotNullOrUndefined).map((sale) => formatSale(sale));
  const obj = toMap(arr, "id");
  return { arr, obj };
}

export function formatUserDataForSale(sale: ISale, userData?: ISaleAccount, decimals?: number): ISale {
  const formattedUserData = formatUserData(userData, sale, sale.status, decimals);
  const userFilter = getUserDataFilter(formattedUserData);
  return {
    ...sale,
    userData: formattedUserData,
    filter: {
      participation: userFilter.filterByParticipation,
      result: userFilter.filterByResult,
      status: sale.filter.status,
    },
  };
}

export async function retrieveUserData(
  newSaleArray: ISale[],
  saleContract: SaleContract,
  tokens: { [key: string]: FungibleTokenContract }
) {
  try {
    const userDataArray = await Promise.all(
      newSaleArray.map(async (sale) => {
        const userData = await saleContract.getSaleAccount(sale.id);
        const token = getToken(sale.depositTokenId, tokens);
        return formatUserDataForSale(sale, userData, token?.metadata?.decimals);
      })
    );

    return toMap(userDataArray, "id");
  } catch (e) {
    console.warn(`Error: ${e} while update user data`);
    return {};
  }
}

export async function retrieveFutureSaleResult(pages: number, contract: SaleContract) {
  return (
    await Promise.allSettled(
      [...Array(pages)].map((_, i) => contract.getFutureSales(i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT))
    )
  )
    .filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
}

export async function retrieveFutureSale(contract: SaleContract) {
  const salesLength = await contract.getNumFutureSales();
  const pages = Math.ceil(salesLength ? salesLength / DEFAULT_PAGE_LIMIT : 0);
  const salesResult = await retrieveFutureSaleResult(pages, contract);
  const arr = salesResult.filter(isNotNullOrUndefined).map(([id, sale]) => formatFutureSale(id, sale));
  return { arr, obj: toMap(arr, "id") };
}
