import { ITranslationKeys } from "services/translation";

export enum EDepositErrors {
  Risks = "Risks",
  MaxLimit = "MaxLimit",
  MinLimit = "MinLimit",
  LimitPerTransaction = "LimitPerTransaction",
  NoValue = "NoValue",
  InsufficientFunds = "InsufficientFunds",
}

export const ErrorsMap: { [key: string]: ITranslationKeys } = {
  [EDepositErrors.Risks]: "Errors.Risks",
  [EDepositErrors.MaxLimit]: "Errors.MaxLimit",
  [EDepositErrors.MinLimit]: "Errors.MinLimit",
  [EDepositErrors.LimitPerTransaction]: "Errors.LimitPerTransaction",
  [EDepositErrors.NoValue]: "Errors.NoValue",
  [EDepositErrors.InsufficientFunds]: "Errors.InsufficientFunds",
};
