import { ITranslationKeys } from "services/translation";
import { EMPTY_STRING } from "shared/constants";

import styles from "./styles";
import Timestamp from "./Timestamp";
import Translate from "../Translate";

interface ITimer {
  title: ITranslationKeys | null;
  time?: number;
  textInsteadOfTime?: string;
  hideBackground?: boolean;
}

export default function Timer({ time, title, textInsteadOfTime, hideBackground }: ITimer) {
  if (!title) return null;
  return (
    <styles.TimerBlock hideBackground={hideBackground}>
      <span>
        <Translate value={title} />
      </span>
      {time && !textInsteadOfTime ? (
        <Timestamp time={time} />
      ) : (
        <styles.Time>{textInsteadOfTime || EMPTY_STRING}</styles.Time>
      )}
    </styles.TimerBlock>
  );
}
