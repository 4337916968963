import ModalActionRow from "shared/components/ModalActionRow";
import SmallSkeleton from "shared/components/Placeholder/SmallSkeleton";
import { NUMBER_PLACEHOLDER_CARD } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { getSalesByFilter } from "shared/utils";
import { selectData } from "store/selectors/selectData";

import { Cards } from "./Cards";
import styles from "./styles";

export default function HomePage() {
  const { sales, loading } = useAppSelector(selectData);
  const salesByFilter = getSalesByFilter(sales.arr, sales.filter);

  return (
    <styles.Container>
      <ModalActionRow loading={loading} />
      <styles.WrapperCards>
        {loading ? (
          NUMBER_PLACEHOLDER_CARD.map((sale) => <SmallSkeleton key={sale} />)
        ) : (
          <Cards sales={salesByFilter} future={sales.future.arr} />
        )}
      </styles.WrapperCards>
    </styles.Container>
  );
}
