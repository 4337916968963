import { useWalletData } from "providers/WalletSelectorProvider";
import { wNearAddress } from "services/config";
import { calcLockedAmount } from "shared/calculation";
import Buttons from "shared/components/Buttons";
import modalStyles from "shared/components/Modals/Components/styles";
import ModalWrapper from "shared/components/Modals/ModalWrapper";
import Translate from "shared/components/Translate";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { IBalanceArray } from "shared/interfaces";
import { trimAccountId } from "shared/utils";
import { displayBalance } from "shared/utils/displayAmount";
import { selectBalance } from "store/selectors/selectBalance";
import { selectData } from "store/selectors/selectData";
import { selectToken } from "store/selectors/selectToken";

import styles from "./styles";

export interface ISignOutModal {
  closeModal: () => void;
}

export default function SignOutModal({ closeModal }: ISignOutModal): JSX.Element | null {
  const { signOut } = useWalletData();
  const { sales, user } = useAppSelector(selectData);
  const balance = useAppSelector((state) => selectBalance(state, wNearAddress));
  const token = useAppSelector((state) => selectToken(state, wNearAddress));

  if (!token?.metadata) return null;
  const lockedAmount = calcLockedAmount(sales);
  const balanceArray: IBalanceArray[] = [
    {
      title: "Modals.AvailableBalance",
      value: `${displayBalance(balance, token.metadata.decimals)} ${token.metadata.symbol}`,
    },
    {
      title: "Modals.Locked",
      value: `${displayBalance(lockedAmount, token.metadata.decimals)} ${token.metadata.symbol}`,
    },
  ];

  return (
    <ModalWrapper closeModal={closeModal}>
      <modalStyles.Header>
        <p>
          <Translate value="Modals.User" />
        </p>
        <modalStyles.Close onClick={closeModal}>
          <modalStyles.CloseIcon />
        </modalStyles.Close>
      </modalStyles.Header>
      <styles.WalletWrapper>
        <styles.NearLogo />
        <p>{trimAccountId(user.id)}</p>
      </styles.WalletWrapper>
      <styles.BalanceWrapper>
        {balanceArray.map(({ title, value }) => (
          <styles.Row key={title}>
            <p>
              <Translate value={title} />
            </p>
            <p>{value}</p>
          </styles.Row>
        ))}
      </styles.BalanceWrapper>
      <styles.Footer>
        <Buttons.Secondary onClick={() => signOut()}>
          <Translate value="Action.Disconnect" />
        </Buttons.Secondary>
      </styles.Footer>
    </ModalWrapper>
  );
}
