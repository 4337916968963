import { SaleOutput } from "services/interfaces";
import { ISale, VestingProps } from "shared/interfaces";
import { EFilterByParticipation, EFilterByResult, ESocial } from "shared/interfaces";

import { getCurrentStatus } from "./calculationDate";
import { statusOrderMap } from "./filter";
import { nanosecondsToMilliseconds } from "./formatAmount";
import { formatVesting, getCliffDetails, getVestingData } from "./vesting";

const formatSale = (sale: SaleOutput): ISale => {
  const {
    name,
    symbol,
    description,
    smart_contract_url,
    logo_url,
    output_ticker,
    project_telegram,
    project_medium,
    project_twitter,
    reward_timestamp,
    reward_description,
  } = sale.metadata;

  const startDate = nanosecondsToMilliseconds(sale.start_date);
  const endDate = nanosecondsToMilliseconds(sale.end_date);

  const rewardDate = reward_timestamp ? nanosecondsToMilliseconds(reward_timestamp) : undefined;

  let vesting: VestingProps | null = null;
  let cliffEndDate: number | null = null;
  if (sale.vesting) {
    const formattedVesting = formatVesting(sale.vesting);
    const vestingData = getVestingData(formattedVesting, endDate);
    vesting = vestingData;
    cliffEndDate = getCliffDetails(formattedVesting);
  }

  const status = getCurrentStatus(
    startDate,
    endDate,
    sale.max_amount,
    sale.collected_amount,
    sale.sale_type,
    cliffEndDate,
    vesting?.arr
  );

  return {
    id: sale.sale_id,
    depositTokenId: sale.deposit_token_id,
    claimAvailable: sale.claim_available,
    refundAvailable: sale.refund_available,
    distributeTokenId: sale.distribute_token_id,
    distributeTokenDecimals: sale.distribute_token_decimals,
    minBuy: sale.min_buy,
    maxBuy: sale.max_buy,
    totalAmount: sale.max_amount,
    startDate,
    endDate,
    rewardDate,
    price: sale.price,
    limitPerTransaction: sale.limit_per_transaction,
    collectedAmount: sale.collected_amount,
    numAccountSales: sale.num_account_sales,
    saleType: sale.sale_type,
    claimBegun: sale.claim_begun,
    metadata: {
      socials: [
        { value: project_medium, type: ESocial.Medium },
        { value: project_telegram, type: ESocial.Telegram },
        { value: project_twitter, type: ESocial.Twitter },
      ],
      name,
      depositSymbol: symbol,
      description,
      projectUrl: smart_contract_url,
      rewardTokenLogo: logo_url,
      rewardTicker: output_ticker,
      rewardDescription: reward_description,
    },
    status,
    filter: {
      status: statusOrderMap[status],
      participation: EFilterByParticipation.ALL,
      result: EFilterByResult.ALL,
    },
    vesting,
    cliffEndDate,
    whitelistHash: sale.whitelist_hash,
  };
};

export default formatSale;
