import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";

import background from "assets/images/background.svg";
import FutureSalePage from "pages/future-sale-page";
import HomePage from "pages/home-page";
import SalePage from "pages/sale-page";
import { useWalletData } from "providers/WalletSelectorProvider";
import useTransactionHash from "services/helpers/receiptsService";
import BurgerMenu from "shared/components/BurgerMenu";
import Footer from "shared/components/Footer";
import Header from "shared/components/Header";
import RefundBanner from "shared/components/RefundBanner";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { useSynchronizer } from "shared/hooks/useSynchronizer";
import { initialLoading } from "store/actions/initialLoading";
import { selectData } from "store/selectors/selectData";
import { selectModalState, closeModal, MODALS, EModals, IModalsProps } from "store/slices/modals";

import { ROUTES } from "./constant";

import "react-toastify/dist/ReactToastify.css";

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(${background});
  background-position: center 5.25rem;
  background-repeat: no-repeat;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    background-position: center 8rem;
    background-size: 44rem;
  `}
`;

const Pages = styled.div<{ isOpened?: boolean }>`
  flex: 1;
  display: ${({ isOpened }) => (isOpened ? "none" : "flex")};
  flex-direction: column;
  position: relative;
  max-width: 100vw;
  z-index: 1;
`;

export default function AppRoutes(): JSX.Element {
  const { RPCProvider, saleContract } = useWalletData();
  const dispatch = useAppDispatch();
  const { user, sales } = useAppSelector(selectData);
  const url = new URL(window.location.href);
  useTransactionHash(url.search, user.id);
  const [isOpened, setIsOpened] = useState<boolean>(false);

  useEffect(() => {
    dispatch(initialLoading({ provider: RPCProvider, saleContract }));
  }, [RPCProvider, saleContract, dispatch]);

  useSynchronizer(sales.arr);

  const modalState = useAppSelector(selectModalState);
  const Modal: IModalsProps[EModals] = MODALS[modalState.modal];

  return (
    <>
      <AnimatePresence>
        {Modal && <Modal closeModal={() => dispatch(closeModal())} {...modalState.props} />}
      </AnimatePresence>
      <Router>
        <Container>
          <Background />
          <ToastContainer />
          <RefundBanner />
          <Header isOpened={isOpened} setIsOpened={setIsOpened} />
          <BurgerMenu isOpened={isOpened} />
          <Pages isOpened={isOpened}>
            <Routes>
              <Route path={ROUTES.HOME} element={<HomePage />} />
              <Route path={ROUTES.SALE_BY_ID} element={<SalePage />} />
              <Route path={ROUTES.FUTURE_SALE_BY_ID} element={<FutureSalePage />} />
              <Route path={ROUTES.DEFAULT} element={<Navigate replace to={ROUTES.HOME} />} />
            </Routes>
          </Pages>
          <Footer />
        </Container>
      </Router>
    </>
  );
}
