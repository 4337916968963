import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

import { WalletSelectorProvider } from "providers/WalletSelectorProvider";
import AppRoutes from "routes";
import theme from "shared/theme";
import store from "store";

import "./services/translation";
import "@near-wallet-selector/modal-ui/styles.css";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <WalletSelectorProvider>
          <AppRoutes />
        </WalletSelectorProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
