import HeaderLogo from "assets/images/project-placeholder.svg";
import ProjectLogo from "assets/images/small-project-placeholder.svg";
import { cdnUrl } from "services/config";
import { TEST_CDN_ID } from "shared/constants";
import { ESalesLogo } from "shared/interfaces";

import styles from "./styles";

interface IMedia {
  saleId: number;
  typeLogo: ESalesLogo;
}

//TODO: added link for future sale
export default function Media({ saleId, typeLogo }: IMedia) {
  const testCdnID = localStorage.getItem(TEST_CDN_ID);
  const id = testCdnID ? Number(testCdnID) : saleId;
  switch (typeLogo) {
    case ESalesLogo.HEADER: {
      const media = `${cdnUrl}/${id}/header.png`;
      return (
        <styles.Image
          src={media}
          alt="HeaderLogo"
          onError={(e) => {
            (e.target as HTMLInputElement).onerror = null;
            (e.target as HTMLInputElement).src = HeaderLogo;
          }}
        />
      );
    }
    case ESalesLogo.LOGO: {
      const media = `${cdnUrl}/${id}/logo.png`;
      return (
        <styles.SmallImage
          src={media}
          alt="ProjectLogo"
          onError={(e) => {
            (e.target as HTMLInputElement).onerror = null;
            (e.target as HTMLInputElement).src = ProjectLogo;
          }}
        />
      );
    }

    default:
      return null;
  }
}
