import Big from "big.js";

import { ITranslationKeys } from "services/translation";
import { ZERO } from "shared/constants";
import { ISale } from "shared/interfaces";

import { displayAmount } from "./displayAmount";
import { formatTokenAmount } from "./formatAmount";

interface ILabel {
  key: ITranslationKeys;
  amount: string;
  currency: string;
}

export interface IParticipantData {
  title: ITranslationKeys;
  additionalTitle?: string;
  amount: string;
  currency: string;
  label?: ILabel | null;
}

export default function getParticipantData(
  sale: ISale,
  currency: string,
  depositTokenDecimals: number
): IParticipantData[] {
  const { rewardTicker } = sale.metadata;
  const depositTokenPrice = formatTokenAmount(sale.price, depositTokenDecimals);
  const formattedDeposited = formatTokenAmount(sale.userData?.deposited || ZERO, depositTokenDecimals);
  const formattedPurchased = formatTokenAmount(sale.userData?.purchase || ZERO, depositTokenDecimals);
  const formattedTotalAmount = formatTokenAmount(sale.totalAmount, depositTokenDecimals);

  const formattedMinAllocation = formatTokenAmount(sale.minBuy, depositTokenDecimals);
  const formattedMaxAllocation = formatTokenAmount(sale.maxBuy, depositTokenDecimals);

  const refundValue =
    sale.userData && Big(sale.userData.refund).gt(ZERO) && Big(sale.userData.refunded).eq(ZERO)
      ? formatTokenAmount(sale.userData.refund, depositTokenDecimals)
      : null;

  const depositedLabel: ILabel | null = refundValue
    ? {
        key: "Sale.Refund.HelperText",
        amount: displayAmount(refundValue),
        currency,
      }
    : null;

  return [
    {
      title: "Sale.Deposited",
      amount: displayAmount(formattedDeposited),
      currency,
      label: depositedLabel,
    },
    {
      title: "Sale.Purchased",
      amount: displayAmount(formattedPurchased),
      currency: rewardTicker,
    },
    {
      title: "Sale.MinAllocation",
      amount: displayAmount(formattedMinAllocation),
      currency,
    },
    {
      title: "Sale.MaxAllocation",
      amount: displayAmount(formattedMaxAllocation),
      currency,
    },
    {
      title: "Sale.Price",
      additionalTitle: rewardTicker,
      amount: depositTokenPrice,
      currency,
    },
    {
      title: "Sale.Target",
      amount: displayAmount(formattedTotalAmount),
      currency,
    },
  ];
}
