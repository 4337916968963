import { useCallback, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

import { useWalletData } from "providers/WalletSelectorProvider";
import { ROUTES } from "routes/constant";
import Translate from "shared/components/Translate";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { trimAccountId } from "shared/utils";
import { selectData } from "store/selectors/selectData";
import { EModals, showModal } from "store/slices/modals";

import BurgerToggle from "./BurgerToggle";
import styles from "./styles";
import BlockhainDomainsImage from "../BlockchainDomainsImage";

interface IHeader {
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

export default function Header({ isOpened, setIsOpened }: IHeader): JSX.Element | null {
  const navigate = useNavigate();
  const { openModal } = useWalletData();
  const { loading, user } = useAppSelector(selectData);
  const dispatch = useAppDispatch();
  const isConnected = user.isSignedIn;
  const title = isConnected ? trimAccountId(user.id) : <Translate value="Action.ConnectWallet" />;

  const handleClick = useCallback(() => {
    if (isConnected) {
      dispatch(showModal({ modal: EModals.SIGN_OUT_MODAL, props: {} }));
    } else {
      openModal();
    }
  }, [dispatch, isConnected, openModal]);

  return (
    <styles.Container>
      <styles.LeftRow>
        <BurgerToggle isOpened={isOpened} setIsOpened={setIsOpened} />
        <styles.BocaChicaLogo onClick={() => navigate(ROUTES.HOME)} isLoading={loading} isOpened={isOpened}>
          <styles.BocaChica />
        </styles.BocaChicaLogo>
      </styles.LeftRow>
      <BlockhainDomainsImage loading={loading} isOpened={isOpened} />
      <styles.ButtonConnect onClick={handleClick} isLoading={loading} isOpened={isOpened}>
        <styles.WalletLogo />
        {title}
      </styles.ButtonConnect>
    </styles.Container>
  );
}
