export enum EFilterByStatus {
  ALL = "ALL",
  OPEN = "OPEN",
  SOON = "SOON",
  CLOSED = "CLOSED",
  CANCELLED = "CANCELLED",
}

export enum EFilterByParticipation {
  ALL = "ALL",
  JOINED = "JOINED",
}

export enum EFilterByResult {
  ALL = "ALL",
  CLAIM = "CLAIM",
  REFUND = "REFUND",
  BOTH = "BOTH",
}

export interface IFilter {
  status: EFilterByStatus;
  participation: EFilterByParticipation;
  result: EFilterByResult;
}
