import Big from "big.js";
import { Dispatch, SetStateAction } from "react";

import { ZERO } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { ISale, EStatus, EWhitelistedStatus } from "shared/interfaces";
import { displayBalance, enforcer, validateValue, EDepositErrors, formatTokenAmount } from "shared/utils";
import { selectIsSignedIn } from "store/slices/user";

import styles from "./styles";
import Translate from "../Translate";

interface IAmountInputModel {
  amount: string;
  setAmount: Dispatch<SetStateAction<string>>;
  sale: ISale;
  balance: string | null;
  currency: string;
  depositTokenDecimals: number;
  status: EStatus;
  error: EDepositErrors | null;
  setError: Dispatch<SetStateAction<EDepositErrors | null>>;
  isAssent: boolean;
  isUserWhitelisted: EWhitelistedStatus;
}

export default function AmountInputContainer({
  amount,
  setAmount,
  sale,
  balance,
  currency,
  depositTokenDecimals,
  status,
  error,
  setError,
  isAssent,
  isUserWhitelisted,
}: IAmountInputModel) {
  const isSignedIn = useAppSelector(selectIsSignedIn);
  const disabled = !isSignedIn || status !== EStatus.OPEN || isUserWhitelisted === EWhitelistedStatus.NOT_WHITELISTED;

  const handlerChange = ({ target }: { target: HTMLInputElement }) => {
    const value = enforcer(target);
    const validateAmount = validateValue({
      isAssent,
      value,
      balance: balance || ZERO,
      min: sale.minBuy,
      max: sale.maxBuy,
      deposited: sale.userData?.deposited || ZERO,
      decimal: depositTokenDecimals,
      limitPerTransaction: sale.limitPerTransaction,
      refund: sale.userData?.refund || ZERO,
    });
    setAmount(value);
    setError(validateAmount);
  };

  const calculateMaxValue = () => {
    if (sale && status !== EStatus.OPEN) return;
    const currentBalance = new Big(balance || ZERO);
    const availableToDeposit = new Big(sale.maxBuy).minus(sale.userData?.deposited ?? "0");
    const formattedAvailableToDeposit = formatTokenAmount(availableToDeposit.toFixed(), depositTokenDecimals);

    if (currentBalance.gte(sale.limitPerTransaction)) {
      if (availableToDeposit.gte(sale.limitPerTransaction)) {
        const formattedLimitPerTransaction = formatTokenAmount(sale.limitPerTransaction, depositTokenDecimals);
        setAmount(formattedLimitPerTransaction);
      } else {
        setAmount(formattedAvailableToDeposit);
      }
    } else if (currentBalance.lte(availableToDeposit)) {
      const formattedCurrentBalance = formatTokenAmount(currentBalance.toFixed(), depositTokenDecimals);
      setAmount(formattedCurrentBalance);
    } else {
      setAmount(formattedAvailableToDeposit);
    }
  };

  return (
    <styles.Wrapper>
      <styles.FieldBox>
        <styles.TokenAccountRow>
          <styles.TokenBalance>
            <styles.WalletIcon />
            {balance ? (
              <Translate
                value="Sale.Balance"
                interpolation={{
                  balance: displayBalance(balance, depositTokenDecimals),
                  currency,
                }}
              />
            ) : (
              <Translate value="Loading" />
            )}
          </styles.TokenBalance>
          <styles.MaxValueBtn onClick={calculateMaxValue} disabled={disabled}>
            <Translate value="Sale.Max" />
          </styles.MaxValueBtn>
        </styles.TokenAccountRow>
        <styles.FieldWrapper>
          <styles.StyledInput
            value={amount}
            onChange={handlerChange}
            inputMode="decimal"
            autoComplete="off"
            autoCorrect="off"
            type="text"
            pattern="^[0-9]*[.,]?[0-9]*$"
            placeholder="0"
            minLength={1}
            maxLength={79}
            spellCheck="false"
            disabled={disabled}
            valid={!error}
          />
          <styles.Currency>{currency}</styles.Currency>
        </styles.FieldWrapper>
      </styles.FieldBox>
    </styles.Wrapper>
  );
}
