import styled from "styled-components";

const Image = styled.img`
  width: 100%;
  height: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  border-radius: 0.25rem;
`;

const SmallImage = styled.img`
  height: 100%;
  max-width: 3rem;
  height: 3rem;
  border-radius: 0.25rem;
`;

export default {
  Image,
  SmallImage,
};
