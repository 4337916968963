import styled from "styled-components";

const OutsideClickContainer = styled.div<{ isFullWidth?: boolean }>`
  max-width: 100%;
  width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "auto")};
`;

export default {
  OutsideClickContainer,
};
