import { createSelector } from "@reduxjs/toolkit";

import { toArray } from "shared/utils";
import { selectFutureSales, selectSales, selectSalesFilter } from "store/slices/sales";
import { selectTokens } from "store/slices/tokens";
import { selectUser } from "store/slices/user";

import { selectLoading } from "./selectLoading";

export const selectData = createSelector(
  [selectSales, selectSalesFilter, selectTokens, selectUser, selectLoading, selectFutureSales],
  (sales, filter, tokens, user, loading, futureSales) => ({
    user,
    sales: {
      future: {
        arr: toArray(futureSales),
        obj: futureSales,
      },
      filter,
      arr: toArray(sales),
      obj: sales,
    },
    tokens,
    loading,
  })
);
