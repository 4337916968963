import { Fragment } from "react";

import Translate from "shared/components/Translate";
import { EVestingStatus, IVesting } from "shared/interfaces";
import { displayAmount } from "shared/utils";

import styles from "../../styles";
import CurrentLogo from "../Logo";

interface IVestingList {
  rewardTicker: string;
  depositTokenDecimals: number;
  vesting: Array<IVesting>;
}

export default function VestingList({ vesting, rewardTicker, depositTokenDecimals }: IVestingList) {
  return (
    <styles.VestingList>
      <styles.HeaderList>
        <p>
          <Translate value="Sale.Vesting.Amount" />
        </p>
        <p>
          <Translate value="Sale.Vesting.UnlockDate" />
        </p>
      </styles.HeaderList>
      <styles.List>
        {vesting.map((row) => (
          <Fragment key={row.date}>
            <styles.Row isEnded={row.status === EVestingStatus.CLOSED}>
              <CurrentLogo status={row.status} startPeriod={row.previousVestingDate} endPeriod={row.date} />
              <p>
                <Translate
                  value="Sale.PriceValue"
                  interpolation={{
                    price: displayAmount(row.amount, depositTokenDecimals),
                    currency: rewardTicker,
                  }}
                />
              </p>
              <p>
                <Translate
                  value="Sale.Vesting.Date"
                  interpolation={{
                    day: row.dateObj.day,
                    month: row.dateObj.month,
                    year: row.dateObj.year,
                  }}
                />
              </p>
            </styles.Row>
            <styles.Division />
          </Fragment>
        ))}
      </styles.List>
    </styles.VestingList>
  );
}
