import { FutureSaleOutput } from "services/interfaces";
import { EMPTY_STRING } from "shared/constants";
import { EStatus, IFutureSale } from "shared/interfaces";
import { ESocial } from "shared/interfaces";

const formatFutureSale = (id: number, futureSale: FutureSaleOutput): IFutureSale => {
  const {
    name,
    symbol,
    description,
    smart_contract_url,
    logo_url,
    output_ticker,
    project_telegram,
    project_medium,
    project_twitter,
  } = futureSale.metadata;

  return {
    id,
    metadata: {
      socials: [
        { value: project_medium, type: ESocial.Medium },
        { value: project_telegram, type: ESocial.Telegram },
        { value: project_twitter, type: ESocial.Twitter },
      ],
      name,
      depositSymbol: symbol,
      description,
      projectUrl: smart_contract_url,
      rewardTokenLogo: logo_url,
      rewardTicker: output_ticker,
      rewardDescription: null,
    },
    status: EStatus.FUTURE,
    depositTokenId: futureSale.deposit_token_id || EMPTY_STRING,
    distributeTokenId: futureSale.distribute_token_id || EMPTY_STRING,
  };
};

export default formatFutureSale;
