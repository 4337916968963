import styled from "styled-components";

import { ReactComponent as ArrowUp } from "assets/images/icons/arrow-up.svg";
import { ReactComponent as WalletLogo } from "assets/images/icons/wallet.svg";

import Buttons from "../Buttons";

const ArrowBtnIcon = styled(ArrowUp)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
`;

const Button = styled(Buttons.Primary)`
  align-self: self-end;
  width: 15rem;
  height: 3rem;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
  :disabled {
    background: ${({ theme }) => theme.grey};
    cursor: not-allowed;
  }
`;

const WalletIcon = styled(WalletLogo)`
  width: 1.313rem;
  height: 1.125rem;
  margin-right: 0.813rem;
`;

export default {
  ArrowBtnIcon,
  Button,
  WalletIcon,
};
