import { ACCOUNT_TRIM_LENGTH } from "shared/constants";

export const lowerCaseExceptFirst = (str: string) => str.charAt(0) + str.slice(1).toLowerCase();

export const trimAccountId = (accountId: string) => {
  if (accountId.length > 16) {
    return `${accountId.slice(0, ACCOUNT_TRIM_LENGTH)}...`;
  }
  return accountId;
};
