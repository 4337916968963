import { AnimatePresence } from "framer-motion";

import { slideBurgerMenuAnimation } from "shared/animations";
import SocialNetwork from "shared/components/SocialNetwork";
import { colors } from "shared/theme";
import { burgerMenuList, launchpadsList, socials } from "shared/utils";

import styles from "./styles";
import Translate from "../Translate";

interface IBurgerMenu {
  isOpened: boolean;
}

export default function BurgerMenu({ isOpened }: IBurgerMenu): JSX.Element {
  return (
    <AnimatePresence>
      {isOpened && (
        <styles.Container initial="close" animate="open" variants={slideBurgerMenuAnimation} exit="close">
          <styles.Column>
            {burgerMenuList.map(({ title, logo: LogoComponent, link }) => (
              <styles.Row key={title} href={link} target="_blank" rel="noreferrer">
                <LogoComponent />
                <Translate value={title} />
              </styles.Row>
            ))}
          </styles.Column>
          <styles.Line />
          <styles.Column>
            {launchpadsList.map(({ title, logo: LogoComponent, link }) => (
              <styles.Row key={title} href={link} target="_blank" rel="noreferrer">
                <LogoComponent />
                <Translate value={title} />
              </styles.Row>
            ))}
          </styles.Column>
          <SocialNetwork socials={socials} color={colors.white} />
        </styles.Container>
      )}
    </AnimatePresence>
  );
}
