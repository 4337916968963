import { Fragment } from "react";

import { ITranslationKeys } from "services/translation";
import { ETypeVesting, VestingProps } from "shared/interfaces";
import { getOrdinalNumberSuffixes } from "shared/utils";

import styles from "./styles";
import Translate from "../Translate";

interface IVestingScheduleWrapper {
  children: JSX.Element | JSX.Element[];
  label?: ITranslationKeys;
}

export function VestingScheduleWrapper({ children, label }: IVestingScheduleWrapper) {
  return (
    <styles.Container>
      <styles.Title>
        <Translate value="Sale.Vesting.Schedule" />
      </styles.Title>
      {label && (
        <styles.Label>
          <Translate value={label} />
        </styles.Label>
      )}
      <styles.Vesting>{children}</styles.Vesting>
    </styles.Container>
  );
}

export default function VestingSchedule({ vesting }: { vesting: VestingProps }) {
  switch (vesting.type) {
    case ETypeVesting.OneTime:
      return (
        <VestingScheduleWrapper>
          {vesting.arr.map((row) => (
            <Fragment key={row.date}>
              <styles.Row isOneTimeVesting>
                <span>
                  <Translate value="Sale.Vesting.UnlockDate" />
                </span>
                <span>
                  <Translate
                    value="Sale.Vesting.Date"
                    interpolation={{
                      day: row.dateObj.day,
                      month: row.dateObj.month,
                      year: row.dateObj.year,
                    }}
                  />
                </span>
              </styles.Row>
              <styles.Division />
            </Fragment>
          ))}
        </VestingScheduleWrapper>
      );
    default:
      return (
        <VestingScheduleWrapper label="Sale.Vesting.UnlockDate">
          {vesting.arr.map((row, index) => (
            <Fragment key={row.date}>
              <styles.Row>
                <span>
                  <Translate
                    value="Sale.Vesting.UnlockRow"
                    interpolation={{
                      number: index + 1,
                      suffix: getOrdinalNumberSuffixes(index + 1),
                      percent: row.quota.toFixed(3),
                    }}
                  />
                </span>
                <span>
                  <Translate
                    value="Sale.Vesting.Date"
                    interpolation={{
                      day: row.dateObj.day,
                      month: row.dateObj.month,
                      year: row.dateObj.year,
                    }}
                  />
                </span>
              </styles.Row>
              <styles.Division />
            </Fragment>
          ))}
        </VestingScheduleWrapper>
      );
  }
}
