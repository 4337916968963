import Big from "big.js";

import { ESaleType } from "services/interfaces";
import { NEAR_TOKEN_ID, ONE_HUNDRED, ZERO } from "shared/constants";
import { ICalcUserData, ISale } from "shared/interfaces";
import { parseTokenAmount, toArray } from "shared/utils";

Big.RM = Big.roundHalfUp;

export const calcUserData = ({
  price,
  totalAmount,
  deposited,
  collectedAmount,
  saleType,
  depositTokenDecimals,
}: ICalcUserData) => {
  if (!deposited || !collectedAmount || collectedAmount === ZERO || price === ZERO) {
    return {
      deposited: ZERO,
      purchase: ZERO,
      refund: ZERO,
    };
  }

  const depositedAmount = new Big(deposited);
  const isGreaterTarget = new Big(collectedAmount).gt(totalAmount);
  const coefficient = new Big(totalAmount).div(collectedAmount);
  const acceptedAmount = new Big(deposited).mul(coefficient);
  const refund = new Big(deposited).minus(acceptedAmount);
  let purchasedAmount = depositedAmount.div(price).toFixed();
  if (
    (saleType === ESaleType.BySubscription || saleType === ESaleType.Cancelled) &&
    isGreaterTarget &&
    refund.gt(ZERO)
  ) {
    purchasedAmount = acceptedAmount.div(price).toFixed();
    const parsedPurchase = parseTokenAmount(purchasedAmount, depositTokenDecimals);
    const calcDeposited = Big(deposited).minus(refund).toFixed();
    return {
      deposited: calcDeposited,
      purchase: parsedPurchase,
      refund: refund.toFixed(),
    };
  }
  const parsedPurchase = parseTokenAmount(purchasedAmount, depositTokenDecimals);
  return {
    deposited: depositedAmount.toFixed(),
    purchase: parsedPurchase,
    refund: ZERO,
  };
};

export const calcLockedAmount = (sales: { [key: number]: ISale }): string => {
  const salesArray = toArray(sales);
  const lockedAmount = salesArray.reduce((acc, sale: ISale) => {
    if (Big(sale.userData?.refunded || ZERO).eq(ZERO) || sale.saleType === ESaleType.ByAmount) return acc;
    if (sale.depositTokenId === NEAR_TOKEN_ID) {
      if (!Big(sale.userData?.refunded || ZERO).eq(ZERO)) {
        return Big(acc)
          .add(sale.userData?.refund || ZERO)
          .toFixed();
      }
    }
    return acc;
  }, ZERO);

  return lockedAmount;
};

export const calcProgress = (collectedAmount: string, totalAmount: string) => {
  if (Big(collectedAmount).eq(ZERO)) return 0;
  const progress = Big(collectedAmount).mul(ONE_HUNDRED).div(totalAmount).toNumber();
  return progress > ONE_HUNDRED ? ONE_HUNDRED : Math.floor(progress);
};

export const calcStrokeDasharray = (radius: number) => 2 * Math.PI * radius;
