import isEqual from "lodash/isEqual";
import { useMemo, useState } from "react";

import Buttons from "shared/components/Buttons";
import modalStyles from "shared/components/Modals/Components/styles";
import ModalWrapper from "shared/components/Modals/ModalWrapper";
import Translate from "shared/components/Translate";
import { DEFAULT_FILTER } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { EFilterByParticipation, EFilterByResult, EFilterByStatus, IFilter } from "shared/interfaces";
import { toArray, lowerCaseExceptFirst } from "shared/utils";
import { selectSalesFilter, updateFilter } from "store/slices/sales";
import { selectIsSignedIn } from "store/slices/user";

import styles from "./styles";

export interface IFilterModal {
  closeModal: () => void;
}

export default function FilterModal({ closeModal }: IFilterModal): JSX.Element {
  const isSignedIn = useAppSelector(selectIsSignedIn);
  const filterType = useAppSelector(selectSalesFilter);
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState<IFilter>(filterType);
  const isGlobalDefaultFilter = useMemo(() => isEqual(DEFAULT_FILTER, filterType), [filterType]);
  const isLocalDefaultFilter = useMemo(() => isEqual(DEFAULT_FILTER, filter), [filter]);
  const sameFilters = useMemo(() => isEqual(filterType, filter), [filter, filterType]);

  const handleConfirm = () => {
    dispatch(updateFilter(filter));
    closeModal();
  };

  const handleCancelConfirm = () => {
    dispatch(updateFilter(filterType));
    closeModal();
  };

  const handleClearFilter = () => {
    setFilter(DEFAULT_FILTER);
  };

  return (
    <ModalWrapper closeModal={closeModal}>
      <modalStyles.Header>
        <p>
          <Translate value="Modals.Filters" />
        </p>
        <modalStyles.Close onClick={closeModal}>
          <modalStyles.CloseIcon />
        </modalStyles.Close>
      </modalStyles.Header>

      <styles.Body>
        <styles.Wrapper>
          <styles.TitleWrapper>
            <Translate value="Filter.Status" />
          </styles.TitleWrapper>
          <styles.WrapperBtn>
            {toArray(EFilterByStatus).map((status) => (
              <styles.FilterBtn
                key={status}
                isActive={status !== filter.status}
                onClick={() =>
                  setFilter({
                    ...filter,
                    status,
                  })
                }
              >
                {lowerCaseExceptFirst(status)}
              </styles.FilterBtn>
            ))}
          </styles.WrapperBtn>
        </styles.Wrapper>
        <styles.Wrapper>
          <styles.TitleWrapper>
            <Translate value="Filter.PoolParticipation" />
          </styles.TitleWrapper>
          <styles.WrapperBtn>
            {toArray(EFilterByParticipation).map((participation) => (
              <styles.FilterBtn
                key={participation}
                isActive={participation !== filter.participation}
                onClick={() =>
                  setFilter({
                    ...filter,
                    participation,
                  })
                }
                disabled={!isSignedIn}
              >
                {lowerCaseExceptFirst(participation)}
              </styles.FilterBtn>
            ))}
          </styles.WrapperBtn>
        </styles.Wrapper>
        <styles.Wrapper>
          <styles.TitleWrapper>
            <Translate value="Filter.Result" />
          </styles.TitleWrapper>
          <styles.WrapperBtn>
            {toArray(EFilterByResult)
              .slice(0, -1)
              .map((result) => (
                <styles.FilterBtn
                  key={result}
                  isActive={result !== filter.result}
                  onClick={() =>
                    setFilter({
                      ...filter,
                      result,
                    })
                  }
                  disabled={!isSignedIn}
                >
                  {lowerCaseExceptFirst(result)}
                </styles.FilterBtn>
              ))}
          </styles.WrapperBtn>
        </styles.Wrapper>
        {(!isGlobalDefaultFilter || !sameFilters) && !isLocalDefaultFilter && (
          <styles.ClearFilter onClick={handleClearFilter}>
            <Translate value="Modals.Clear" />
          </styles.ClearFilter>
        )}
      </styles.Body>
      <styles.Footer>
        <Buttons.Primary onClick={handleConfirm} disabled={sameFilters}>
          <Translate value="Action.Apply" />
        </Buttons.Primary>
        <Buttons.Secondary onClick={handleCancelConfirm}>
          <Translate value="Action.Cancel" />
        </Buttons.Secondary>
      </styles.Footer>
    </ModalWrapper>
  );
}
