import { Dispatch, SetStateAction } from "react";

import { FungibleTokenContract } from "services/contract";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { EStatus, EWhitelistedStatus, ISale } from "shared/interfaces";
import { EDepositErrors, validateCheckbox } from "shared/utils";
import { selectIsSignedIn } from "store/slices/user";

import CurrentTimer from "./CurrentTimer";
import HelperText from "./HelperText";
import styles from "./styles";
import AmountInputContainer from "../AmountInputContainer";
import HandleSaleActionButton from "../HandleSaleActionButton";
import Translate from "../Translate";

interface IDepositSection {
  amount: string;
  setAmount: Dispatch<SetStateAction<string>>;
  sale: ISale;
  balance: string | null;
  token: FungibleTokenContract;
  status: EStatus;
  error: EDepositErrors | null;
  setError: Dispatch<SetStateAction<EDepositErrors | null>>;
  isAssent: boolean;
  setIsAssent: Dispatch<SetStateAction<boolean>>;
  makeDeposit: () => void;
  isUserWhitelisted: EWhitelistedStatus;
}

export default function DepositSection({
  amount,
  setAmount,
  sale,
  balance,
  token,
  status,
  error,
  setError,
  isAssent,
  setIsAssent,
  makeDeposit,
  isUserWhitelisted,
}: IDepositSection) {
  const isSignedIn = useAppSelector(selectIsSignedIn);

  const checkRisks = ({ target }: { target: HTMLInputElement }) => {
    const { checked } = target;
    const validate = validateCheckbox(checked, error);
    setIsAssent(checked);
    setError(validate);
  };
  if (!token.metadata) return null;

  return (
    <styles.Container>
      <styles.ActionContainer>
        <AmountInputContainer
          amount={amount}
          balance={balance}
          currency={token.metadata.symbol}
          depositTokenDecimals={token.metadata.decimals}
          error={error}
          isAssent={isAssent}
          sale={sale}
          setAmount={setAmount}
          setError={setError}
          status={status}
          isUserWhitelisted={isUserWhitelisted}
        />
        <HelperText
          amount={amount}
          rewardTicker={sale.metadata.rewardTicker}
          depositTokenDecimals={token.metadata.decimals}
          error={error}
          sale={sale}
          isUserWhitelisted={isUserWhitelisted}
        />
        <HandleSaleActionButton
          amount={amount}
          makeDeposit={makeDeposit}
          error={error}
          status={status}
          isUserWhitelisted={isUserWhitelisted}
        />
        <CurrentTimer status={status} startDate={sale.startDate} endDate={sale.endDate} />
      </styles.ActionContainer>
      <styles.Approve>
        <styles.AcceptInput>
          <input
            type="checkbox"
            defaultChecked={isAssent}
            onChange={checkRisks}
            disabled={
              !isSignedIn || status !== EStatus.OPEN || isUserWhitelisted === EWhitelistedStatus.NOT_WHITELISTED
            }
          />
          <svg viewBox="0 0 21 21">
            <polyline points="5 10.75 8.5 14.25 16 6" />
          </svg>
        </styles.AcceptInput>
        <styles.ApproveDescription>
          <Translate value="Sale.Approve" />
        </styles.ApproveDescription>
      </styles.Approve>
    </styles.Container>
  );
}
