export enum FTViewMethods {
  ftMetadata = "ft_metadata",
  ftBalanceOf = "ft_balance_of",
  storageBalanceOf = "storage_balance_of",
  storageBalanceBounds = "storage_balance_bounds",
}

export const FTChangeMethods = {
  storageDeposit: "storage_deposit",
  ftTransferCall: "ft_transfer_call",
  depositNear: "deposit_near",
};

export enum SaleViewMethods {
  getNumSales = "get_num_sales",
  getSales = "get_sales",
  getSaleAccount = "get_sale_account",
  getSale = "get_sale",
  hasAccount = "has_account",
  getJoinFee = "get_join_fee",
  getNumFutureSales = "get_num_future_sales",
  getFutureSale = "get_future_sale",
  getFutureSales = "get_future_sales",
}

export const SaleChangeMethods = {
  join: "join", // (&self)
  claimRefund: "claim_refund", // (&self, sale_id: u64)
  claimPurchased: "claim_purchase", // (&self, sale_id: u64)
};
