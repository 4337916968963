import { ESaleType } from "services/interfaces";
import { ZERO, EMPTY_STRING, DEFAULT_FILTER } from "shared/constants";
import { IFutureSale, ISale } from "shared/interfaces";
import { IParticipantData } from "shared/utils/getParticipantData";

export const getEmptyParticipantData = (currency: string, rewardTicker: string): Array<IParticipantData> => [
  {
    title: "Sale.Deposited",
    amount: ZERO,
    currency,
  },
  {
    title: "Sale.Purchased",
    amount: ZERO,
    currency: rewardTicker,
  },
  {
    title: "Sale.MinAllocation",
    amount: EMPTY_STRING,
    currency,
  },
  {
    title: "Sale.MaxAllocation",
    amount: EMPTY_STRING,
    currency,
  },
  {
    title: "Sale.Price",
    additionalTitle: rewardTicker,
    amount: EMPTY_STRING,
    currency,
  },
  {
    title: "Sale.Target",
    amount: EMPTY_STRING,
    currency,
  },
];

export const getInitialSaleData = (futureSale: IFutureSale): ISale => ({
  id: futureSale.id,
  metadata: futureSale.metadata,
  depositTokenId: futureSale.depositTokenId,
  claimAvailable: false,
  refundAvailable: false,
  distributeTokenId: futureSale.distributeTokenId,
  distributeTokenDecimals: 0,
  minBuy: ZERO,
  maxBuy: ZERO,
  totalAmount: ZERO,
  startDate: 0,
  endDate: 0,
  price: ZERO,
  limitPerTransaction: ZERO,
  collectedAmount: ZERO,
  numAccountSales: 0,
  saleType: ESaleType.ByAmount,
  claimBegun: false,
  status: futureSale.status,
  filter: DEFAULT_FILTER,
  vesting: null,
  cliffEndDate: null,
  whitelistHash: null,
});
