import { configureStore } from "@reduxjs/toolkit";

import { modalsReducer } from "./slices/modals";
import { salesReducer } from "./slices/sales";
import { tokensReducer } from "./slices/tokens";
import { userReducer } from "./slices/user";

const store = configureStore({
  reducer: {
    sales: salesReducer,
    user: userReducer,
    tokens: tokensReducer,
    modals: modalsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
