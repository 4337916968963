export const modalVariants = {
  initial: {
    y: "-20px",
    opacity: 0,
  },
  open: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.3,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  close: {
    y: "20px",
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};

export const slideBurgerMenuAnimation = { ...modalVariants, open: { ...modalVariants.open, display: "flex" } };

export const backgroundLayoutVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};
