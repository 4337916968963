import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EMPTY_STRING } from "shared/constants";
import { IBalance } from "shared/interfaces";
import { IUserWhitelistData } from "shared/utils/getUserWhitelistStatus";
import { RootState } from "store";

export interface IUserState {
  id: string;
  isSignedIn: boolean;
  balances: { [key: string]: IBalance };
  whitelist: { [key: string]: IUserWhitelistData };
}

const initialState: IUserState = {
  id: EMPTY_STRING,
  isSignedIn: false,
  balances: {},
  whitelist: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAccountId: (s, { payload }: PayloadAction<string>) => void (s.id = payload),
    setIsSignedIn: (s, { payload }: PayloadAction<boolean>) => void (s.isSignedIn = payload),
    setBalances: (s, { payload }: PayloadAction<{ [key: string]: IBalance }>) => void (s.balances = payload),
    updateTokenBalance: (s, { payload }: PayloadAction<{ id: string; balance: IBalance }>) =>
      void (s.balances[payload.id] = payload.balance),
    setWhitelist: (state, action: PayloadAction<{ [key: string]: IUserWhitelistData }>) => {
      state.whitelist = action.payload;
    },
  },
});

// SELECTORS
export const selectUser = (s: RootState) => s.user;
export const selectAccountId = (s: RootState) => s.user.id;
export const selectIsSignedIn = (s: RootState) => s.user.isSignedIn;
export const selectBalances = (s: RootState) => s.user.balances;
export const selectWhitelist = (state: RootState) => state.user.whitelist;

// ACTIONS
export const { setAccountId, setBalances, setIsSignedIn, updateTokenBalance, setWhitelist } = userSlice.actions;

export const userReducer = userSlice.reducer;
