import Big from "big.js";

import { ZERO } from "shared/constants";
import { IAmountValidateArgs } from "shared/interfaces";

import { EDepositErrors } from "./errorsLocales";
import { parseTokenAmount } from "./formatAmount";

export const validateValue = ({
  isAssent,
  value,
  balance,
  min,
  max,
  deposited,
  limitPerTransaction,
  decimal,
  isButtonClick,
  refund,
}: IAmountValidateArgs): EDepositErrors | null => {
  const depositedWithRefund = Big(deposited).plus(refund).toFixed();
  if (!value) {
    return EDepositErrors.NoValue;
  }
  const amount = new Big(parseTokenAmount(value || ZERO, decimal));
  const maxAllocation = new Big(max);
  const minAllocation = new Big(min);

  if (amount.gt(balance)) {
    return EDepositErrors.InsufficientFunds;
  }
  if (amount.gt(maxAllocation.minus(depositedWithRefund))) {
    return EDepositErrors.MaxLimit;
  }
  if (amount.lt(minAllocation.minus(depositedWithRefund)) || amount.lte(0)) {
    return EDepositErrors.MinLimit;
  }

  if (amount.gt(limitPerTransaction)) {
    return EDepositErrors.LimitPerTransaction;
  }
  if (!isAssent && isButtonClick) return EDepositErrors.Risks;
  return null;
};

export const validateCheckbox = (checked: boolean, error: EDepositErrors | null): EDepositErrors | null => {
  if (checked && error !== EDepositErrors.Risks) return error;
  if (!checked) return EDepositErrors.Risks;
  return null;
};
