import Big from "big.js";

import { formatTokenAmount } from "./formatAmount";

export const removeTrailingZeros = (amount: string) => {
  if (amount.includes(".") || amount.includes(",")) {
    return amount.replace(/\.?0*$/, "");
  }
  return amount;
};

export const displayAmount = (amount: string, decimals?: number) => {
  const formateAmount = formatTokenAmount(amount, decimals || 0);
  const amountBig = new Big(formateAmount);
  if (amountBig.eq("0")) return "0";
  if (amountBig.lte("0.01")) return ">0.01";
  return `${removeTrailingZeros(amountBig.toFixed(3))}`;
};

export const displayBalance = (amount: string, decimals: number) => {
  const formateAmount = formatTokenAmount(amount, decimals);
  const amountBig = new Big(formateAmount);
  if (amountBig.eq("0")) return "0";
  if (amountBig.lte("0.0001")) return ">0.0001";
  return `${removeTrailingZeros(amountBig.toFixed(3))}`;
};

export const getOrdinalNumberSuffixes = (number: number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
