import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { toFutureSale } from "routes/constant";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { EStatus, IFutureSale } from "shared/interfaces";
import { selectToken } from "store/selectors/selectToken";

import styles from "./styles";
import SaleLayoutInfo from "../SaleLayoutInfo";
import Timer from "../Timer";
import Translate from "../Translate";

export function FutureCard({ sale }: { sale: IFutureSale }) {
  const navigate = useNavigate();
  const token = useAppSelector((state) => selectToken(state, sale.depositTokenId));
  if (!token?.metadata) return null;

  return (
    <styles.Container onClick={() => navigate(toFutureSale(sale.id))} isOpen={sale.status === EStatus.OPEN}>
      <SaleLayoutInfo
        name={sale.metadata.name}
        tokenIcon={token.metadata.icon}
        currency={token.metadata.symbol}
        status={sale.status}
        saleId={sale.id}
      />
      <styles.Footer>
        <styles.SaleInfoWrapper>
          <styles.SaleInfoBlock>
            <p>
              <Translate value="Sale.Price" interpolation={{ currency: sale.metadata.rewardTicker }} />
            </p>
            <p>{token.metadata.symbol}</p>
          </styles.SaleInfoBlock>
          <styles.SaleInfoBlock>
            <p>
              <Translate value="Sale.Target" />
            </p>
            <p>{token.metadata.symbol}</p>
          </styles.SaleInfoBlock>
        </styles.SaleInfoWrapper>
        <styles.RightSide>
          <Timer title="Status.LeftToStart" textInsteadOfTime={t("Status.ComingSoon")} />
          <styles.ArrowLogoContainer>
            <styles.ArrowIcon />
          </styles.ArrowLogoContainer>
        </styles.RightSide>
      </styles.Footer>
    </styles.Container>
  );
}
