import { ESaleType } from "services/interfaces";
import { ITranslationKeys } from "services/translation";

export interface IBurgerMenu {
  title: ITranslationKeys;
  logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  link: string;
}

export interface ITimeLeft {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

export interface IAmountValidateArgs {
  isAssent: boolean;
  value: string;
  balance: string;
  min: string;
  max: string;
  deposited: string;
  limitPerTransaction: string;
  decimal: number;
  isButtonClick?: boolean;
  refund: string;
}

export interface IBalanceArray {
  title: ITranslationKeys;
  value: string;
}

export interface IParseDate {
  year: number;
  month: string;
  day: string;
  hours: string;
  minutes: string;
}

export interface ICalcUserData {
  price: string;
  totalAmount: string;
  deposited: string;
  collectedAmount: string;
  saleType: ESaleType;
  depositTokenDecimals: number;
}

export enum ESalesLogo {
  HEADER,
  LOGO,
}

export enum EWhitelistedStatus {
  WHITELISTED = "WHITELISTED",
  NOT_WHITELISTED = "NOT_WHITELISTED",
  UNKNOWN = "UNKNOWN",
}

export * from "./sale";
export * from "./filter";
export * from "./tokens";
export * from "./user";
export * from "./vesting";
