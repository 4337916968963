import { ReactComponent as BookIcon } from "assets/images/icons/book.svg";
import { ReactComponent as LightningIcon } from "assets/images/icons/lightning.svg";
import { ReactComponent as LinkIcon } from "assets/images/icons/link.svg";
import { ReactComponent as ListIcon } from "assets/images/icons/list.svg";
import { IBurgerMenu } from "shared/interfaces";

export const burgerMenuList: IBurgerMenu[] = [
  {
    title: "BurgerMenu.About",
    logo: LightningIcon,
    link: "https://bocachica.io/",
  },
  {
    title: "BurgerMenu.Whitepaper",
    logo: BookIcon,
    link: "https://bocachica-static.fra1.digitaloceanspaces.com/docs/BCMEWP.pdf",
  },
  {
    title: "BurgerMenu.RisksAndRules",
    logo: ListIcon,
    link: "https://bocachica-static.fra1.digitaloceanspaces.com/docs/bcme_risks.pdf",
  },
];

export const launchpadsList: IBurgerMenu[] = [
  {
    title: "BurgerMenu.OpenMoon",
    logo: LinkIcon,
    link: "https://moon.bocachica.io/",
  },
  {
    title: "BurgerMenu.OpenNFT",
    logo: LinkIcon,
    link: "https://auction.bocachica.io/",
  },
];
