import { ESaleType } from "services/interfaces";
import { ITranslationKeys } from "services/translation";

export const getTarget = (type?: ESaleType): ITranslationKeys | undefined => {
  switch (type) {
    case ESaleType.ByAmount:
      return "Sale.Type.Amount";
    case ESaleType.BySubscription:
      return "Sale.Type.Subscription";
    default:
      return undefined;
  }
};
