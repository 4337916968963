import styled from "styled-components";

import { Skeleton } from "shared/components/Placeholder/Skeleton";

const Description = styled.div`
  margin-top: 2rem;

  div {
    margin-top: 1rem;
    width: 100%;
    height: 0.5rem;
    border-radius: 0.25rem;

    :first-child {
      margin-top: 0;
    }
    :last-child {
      width: 16.523rem;
    }
  }
`;

export default function DescriptionParagraph() {
  return (
    <Description>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Description>
  );
}
