import Big from "big.js";
import { utils } from "near-api-js";

import { DEFAULT_GAS, EMPTY_STRING, ZERO } from "shared/constants";

export { default as formatSale } from "./formatSale";
export { default as formatFutureSale } from "./formatFutureSale";
export { default as formatUserData } from "./formatUserData";
export { default as getParticipantData } from "./getParticipantData";
export * from "./socialLinks";
export * from "./burgerMenuLists";
export * from "./formatAmount";
export * from "./blockhainDomains";
export * from "./calculationDate";
export * from "./displayAmount";
export * from "./statusLocales";
export * from "./errorsLocales";
export * from "./validations";
export * from "./stringOperation";
export * from "./filter";
export * from "./vesting";

export const isNullOrEmpty = (str: string | null) => str?.length === 0 || str === null;

export function isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

export const onlyUniqueValues = (values: string[]) => Array.from(new Set(values));
export const toArray = <T>(map: Record<string | number, T>): Array<T> => Object.values(map);

type KeyType = string | number | symbol;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toMap = <T extends Record<KeyType, any>, K extends keyof T>(
  array: Array<T>,
  keyName: K
): Record<T[K], T> =>
  array.reduce(
    (acc, item) => ({
      ...acc,
      [item[keyName]]: item,
    }),
    {} as Record<T[K], T>
  );

export const inputRegex = RegExp("^\\d*(?:\\\\[.])?\\d*$");

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export const enforcer = (target: HTMLInputElement): string => {
  let value = "";
  const nextUserInput = target.value.replace(/,/g, ".");
  if (nextUserInput[0] === "." || nextUserInput[0] === ",") {
    return `0${nextUserInput}`;
  }
  if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
    value = nextUserInput;
  }

  return value;
};
export const getGas = (gas?: string): string => (gas ? new Big(gas).toFixed() : new Big(DEFAULT_GAS).toFixed());

export const getAmount = (amount?: string): string => new Big(utils.format.parseNearAmount(amount) || ZERO).toFixed();

export const uint8ArrayToHex = (uint8Array: Array<number>): string => {
  return uint8Array.reduce((acc, item) => {
    const hexByte = item.toString(16).padStart(2, "0");
    return acc + hexByte;
  }, EMPTY_STRING);
};
