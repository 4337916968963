import { createSelector } from "@reduxjs/toolkit";

import { ZERO } from "shared/constants";
import { RootState } from "store";
import { selectBalances } from "store/slices/user";

export const selectBalance = createSelector(
  [selectBalances, (_: RootState, id: string) => id],
  (balances, id): string => {
    const balance = balances[id] || null;
    return balance?.balance || ZERO;
  }
);
