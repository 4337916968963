import styled from "styled-components";

import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { hoverTransition } from "shared/theme/transitions";

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: center;
  & > p {
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;

const Close = styled.div`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: ${hoverTransition};

  &:hover {
    opacity: 0.8;
  }

  & > svg {
    fill: black;
  }
`;

export default {
  Header,
  Close,
  CloseIcon,
};
