import { t } from "i18next";
import { Helmet } from "react-helmet";

import bcmarsShare from "assets/images/bcmars-share.jpg";

interface IMetadata {
  title: string;
  description: string;
}

export function Metadata({ title, description }: IMetadata) {
  return (
    <Helmet>
      <meta name="title" content={t("Metadata.Title", { value: title })} />
      <meta name="description" content={description} />
      <meta property="og:image" content={bcmarsShare} />
    </Helmet>
  );
}
