import styled, { keyframes } from "styled-components";

import { ReactComponent as ErrorLogo } from "assets/images/icons/error-icon.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 2fr 1fr;
  gap: 1rem;
  & > div:first-child {
    grid-area: 1 / 1 / 2 / 2;
  }
  & > p {
    grid-area: 2 / 1 / 3 / 2;
  }
  & > button {
    grid-area: 1 / 2 / 2 / 3;
  }
  & > div:last-child {
    grid-area: 2 / 2 / 3 / 3;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    flex-direction: column;
  `}
`;

const Approve = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: start;
  position: relative;
`;

const bounce = keyframes`
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
`;

const AcceptInput = styled.label`
  position: relative;
  input,
  svg {
    width: 1rem;
    height: 1rem;
    display: block;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: ${({ theme }) => theme.white};
    border: none;
    margin: 0.063rem 0 0 0.25rem;
    cursor: pointer;
    border-radius: 4px;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 0.063rem ${({ theme }) => theme.primaryGrey};
    :hover {
      box-shadow: inset 0 0 0 0.125rem ${({ theme }) => theme.primaryGrey};
    }
    :checked {
      box-shadow: inset 0 0 0 0.688rem ${({ theme }) => theme.orange};
      & + svg {
        animation: ${bounce} 0.4s linear forwards 0.2s;
      }
    }
    :disabled {
      box-shadow: inset 0 0 0 0.063rem ${({ theme }) => theme.primaryGrey};
      cursor: not-allowed;
    }
  }
  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: ${({ theme }) => theme.white};
    position: absolute;
    top: 1px;
    left: 4px;
    transform: scale(0) translateZ(0);
  }
`;

const ApproveDescription = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.lightGrey};
  padding-left: 0.813rem;
`;

const HelperText = styled.p`
  color: ${({ theme }) => theme.black};
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Error = styled(HelperText)`
  color: ${({ theme }) => theme.red};
`;

const ErrorIcon = styled(ErrorLogo)`
  margin-right: 0.25rem;
`;

export default {
  Container,
  ActionContainer,
  Approve,
  AcceptInput,
  ApproveDescription,
  Error,
  HelperText,
  ErrorIcon,
};
