import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styles from "pages/sale-page/styles";
import { ROUTES } from "routes/constant";
import stylesProgressBar from "shared/components/AmountProgress/styles";
import DepositSection from "shared/components/DepositSection";
import { Metadata } from "shared/components/Metadata";
import BigSkeleton from "shared/components/Placeholder/BigSkeleton";
import stylesSaleDate from "shared/components/SaleDate/styles";
import SaleLayoutInfo from "shared/components/SaleLayoutInfo";
import SocialNetwork from "shared/components/SocialNetwork";
import Translate from "shared/components/Translate";
import ValueSale from "shared/components/ValueSale";
import { EMPTY_STRING } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { EWhitelistedStatus } from "shared/interfaces";
import { colors } from "shared/theme";
import { EDepositErrors } from "shared/utils";
import { selectBalance } from "store/selectors/selectBalance";
import { selectData } from "store/selectors/selectData";
import { selectToken } from "store/selectors/selectToken";

import { getEmptyParticipantData, getInitialSaleData } from "./utils";

export default function FutureSalePage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, sales } = useAppSelector(selectData);

  const [isAssent, setIsAssent] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>(EMPTY_STRING);
  const [error, setError] = useState<EDepositErrors | null>(null);

  const futureSale = sales.future.obj[Number(id)] || null;
  const token = useAppSelector((state) => selectToken(state, futureSale?.depositTokenId || EMPTY_STRING));
  const balance = useAppSelector((state) => selectBalance(state, futureSale?.depositTokenId || EMPTY_STRING));
  if (loading || !futureSale || !token?.metadata) return <BigSkeleton />;
  if (!loading && !futureSale) navigate(ROUTES.HOME);

  const participantData = getEmptyParticipantData(token.metadata.symbol, futureSale.metadata.rewardTicker);
  const simpleSale = getInitialSaleData(futureSale);
  return (
    <styles.Container>
      <Metadata title={futureSale.metadata.name} description={futureSale.metadata.description} />
      <styles.Wrapper>
        <styles.CloseBtn onClick={() => navigate(ROUTES.HOME)} />
        <SaleLayoutInfo
          currency={token.metadata.symbol}
          isSalePage
          name={futureSale.metadata.name}
          tokenIcon={token.metadata.icon}
          status={futureSale.status}
          saleId={futureSale.id}
        />
        <DepositSection
          amount={amount}
          balance={balance}
          token={token}
          error={error}
          isAssent={isAssent}
          sale={simpleSale}
          setAmount={setAmount}
          setError={setError}
          status={futureSale.status}
          setIsAssent={setIsAssent}
          makeDeposit={() => undefined}
          isUserWhitelisted={EWhitelistedStatus.UNKNOWN}
        />
        <styles.InfoWrapper>
          <ValueSale participantData={participantData} />
          <stylesProgressBar.AmountBlock>
            <stylesProgressBar.Title>
              <Translate value="Sale.Progress" />
            </stylesProgressBar.Title>
            <progress />
            <stylesProgressBar.Label>
              <span>
                <Translate
                  value="Sale.ProgressPercent"
                  interpolation={{
                    progress: 0,
                  }}
                />
              </span>
              <span>{token.metadata.symbol}</span>
            </stylesProgressBar.Label>
          </stylesProgressBar.AmountBlock>
          <stylesSaleDate.DeadlineWrapper>
            <div>
              <span>
                <Translate value="Sale.StartDate" />
              </span>
              <p>
                <Translate value="Status.ComingSoon" />
              </p>
            </div>
          </stylesSaleDate.DeadlineWrapper>
        </styles.InfoWrapper>
        <styles.Description>{futureSale.metadata.description}</styles.Description>
        <styles.SocialWrapper>
          <styles.SocialBox href={futureSale.metadata.projectUrl}>
            <p>{futureSale.metadata.name}</p>
            <styles.ExternalLink />
          </styles.SocialBox>
          <SocialNetwork socials={futureSale.metadata.socials} color={colors.black} isCardPage />
        </styles.SocialWrapper>
      </styles.Wrapper>
    </styles.Container>
  );
}
