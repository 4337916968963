import styled from "styled-components";

const TimerBlock = styled.div<{ hideBackground?: boolean }>`
  height: 1.75rem;
  border-radius: 4px;
  width: 100%;
  max-width: ${({ hideBackground }) => (hideBackground ? "23.125rem" : "17rem")};
  margin-top: ${({ hideBackground }) => (hideBackground ? "1.125rem" : "0")};
  background: ${({ hideBackground, theme }) => (hideBackground ? "transparent" : `rgba(${theme.bgGrey}, 0.2)`)};
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
  span {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.primaryGrey};
    margin-right: 0.25rem;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
  `}
`;

const Time = styled.div`
  display: flex;
  min-width: 8.125rem;
  font-weight: 600;
  justify-content: end;
`;

const Divider = styled.div`
  width: 0.063rem;
  height: 1.188rem;
  background: ${({ theme }) => theme.primaryGrey};
  margin: 0 0.5rem;
  border-radius: 1px;
`;

export default {
  TimerBlock,
  Time,
  Divider,
};
