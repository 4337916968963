import { ReactComponent as BlockchainIconNear } from "assets/images/icons/near-icon.svg";
import { ReactComponent as BlockchainIconSolana } from "assets/images/icons/solana-icon.svg";

enum BlockhainImgEnum {
  Near,
  Solana,
}

export const blockhainDomains: { value: string; type: BlockhainImgEnum; name: string }[] = [
  {
    value: "https://mars.bocachica.io/",
    type: BlockhainImgEnum.Near,
    name: "NEAR",
  },
  {
    value: "https://moon.bocachica.io/",
    type: BlockhainImgEnum.Solana,
    name: "Solana",
  },
];

export const DomainImageMap: { [key: number]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
  [BlockhainImgEnum.Near]: BlockchainIconNear,
  [BlockhainImgEnum.Solana]: BlockchainIconSolana,
};
