import styled from "styled-components";

const ButtonConnect = styled.button<{ isLoading?: boolean }>`
  min-width: 8.063rem;
  height: 2.25rem;
  background: ${({ theme, isLoading }) => (isLoading ? theme.opacityGrey : theme.orange)};
  border-radius: 0.5rem;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, isLoading }) => (isLoading ? theme.transparent : theme.white)};
  cursor: pointer;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.938rem;
  & > svg {
    margin-right: 0.5rem;
    opacity: ${({ isLoading }) => (isLoading ? "0" : "1")};
  }
  transition: background 0.3s;
  :hover {
    background-color: ${({ theme }) => theme.orangeHover};
  }
  :active {
    background-color: ${({ theme }) => theme.orangeActive};
  }
`;

const Primary = styled.button<{ isActive?: boolean }>`
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0 0.5rem;

  font-weight: 600;
  font-size: 1rem;
  line-height: 1.188rem;

  max-height: 3rem;
  min-height: 2.25rem;

  color: ${({ theme, isActive }) => (isActive ? theme.orange : theme.white)};
  background-color: ${({ theme, isActive }) => (isActive ? theme.lightOrange : theme.orange)};

  transition: background 0.3s;
  :hover {
    background-color: ${({ theme, isActive }) => (isActive ? theme.lightOrangeHover : theme.orangeHover)};
  }
  :active {
    background-color: ${({ theme, isActive }) => (isActive ? theme.lightOrangeActive : theme.orangeActive)};
  }
  :disabled {
    cursor: not-allowed;
    color: ${({ theme, isActive }) => (isActive ? theme.grayOp04 : theme.white)};
    background-color: ${({ theme, isActive }) => (isActive ? theme.grayOp01 : theme.grayOp04)};
  }
`;

const Secondary = styled(Primary)`
  color: ${({ theme }) => theme.orange};
  background-color: ${({ theme }) => theme.lightOrange};

  :hover {
    background-color: ${({ theme }) => theme.lightOrangeHover};
  }
  :active {
    background-color: ${({ theme }) => theme.lightOrangeActive};
  }
  :disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.grayOp04};
    background-color: ${({ theme }) => theme.grayOp01};
  }
`;

export default {
  ButtonConnect,
  Primary,
  Secondary,
};
