import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import FilterModal, { IFilterModal } from "shared/components/Modals/FilterModal";
import SearchModal, { ISearchModal } from "shared/components/Modals/SearchModal";
import ShareModal, { IShareModal } from "shared/components/Modals/ShareModal";
import SignOutModal, { ISignOutModal } from "shared/components/Modals/SignOutModal";
import { RootState } from "store";

export enum EModals {
  EMPTY = "EMPTY",
  FILTER_MODAL = "FILTER_MODAL",
  SEARCH_MODAL = "SEARCH_MODAL",
  SIGN_OUT_MODAL = "SIGN_OUT_MODAL",
  SHARE_MODAL = "SHARE_MODAL",
}

export type IModalsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [EModals.EMPTY]: any;
  [EModals.FILTER_MODAL]: IFilterModal;
  [EModals.SEARCH_MODAL]: ISearchModal;
  [EModals.SIGN_OUT_MODAL]: ISignOutModal;
  [EModals.SHARE_MODAL]: IShareModal;
};

export type IModals = {
  [EModals.EMPTY]: null;
  [EModals.FILTER_MODAL]: React.FC<IFilterModal>;
  [EModals.SEARCH_MODAL]: React.FC<ISearchModal>;
  [EModals.SIGN_OUT_MODAL]: React.FC<ISignOutModal>;
  [EModals.SHARE_MODAL]: React.FC<IShareModal>;
};

export const MODALS: IModals = {
  [EModals.EMPTY]: null,
  [EModals.FILTER_MODAL]: FilterModal,
  [EModals.SEARCH_MODAL]: SearchModal,
  [EModals.SIGN_OUT_MODAL]: SignOutModal,
  [EModals.SHARE_MODAL]: ShareModal,
};

export type ModalProps<T extends EModals> = Omit<IModalsProps[T], "closeModal">;

interface IModalState {
  modal: EModals;
  props: IModalsProps[EModals];
}

const initialState: IModalState = {
  modal: EModals.EMPTY,
  props: null,
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    showModal: (_, action: PayloadAction<IModalState>) => action.payload,
    closeModal: () => initialState,
  },
});

// SELECTORS
export const selectModalState = (state: RootState) => state.modals;

// ACTIONS
export const { showModal, closeModal } = modalsSlice.actions;

export const modalsReducer = modalsSlice.reducer;
