import { FinalExecutionOutcome } from "@near-wallet-selector/core";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";

import { contractId } from "services/config";
import { SaleContract } from "services/contract";
import { Action } from "services/interfaces";
import RPCProviderService from "services/RPCProviderService";
import ToastService from "shared/components/Toast";
import { SaleTransactionProps } from "shared/interfaces";
import { RootState } from "store";

export const claim = createAsyncThunk<
  void,
  {
    provider: RPCProviderService;
    requestSignTransactions: (t: Action[]) => Promise<void | FinalExecutionOutcome[]>;
    transactionProps: SaleTransactionProps;
  },
  { state: RootState }
>("sales/claim", async ({ provider, requestSignTransactions, transactionProps }, { getState }) => {
  try {
    const state = getState();
    const accountId = state.user.id;
    const saleContract = new SaleContract(contractId, provider, accountId);
    const { saleId, token } = transactionProps;
    const actions = await saleContract.claimPurchased({ saleId, token });
    await requestSignTransactions(actions);
  } catch (error) {
    ToastService.error(t("Toast.ClaimPurchase"));
    console.error("Error during user claim: ", error);
  }
});
