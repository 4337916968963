import Translate from "shared/components/Translate";
import { EStatus } from "shared/interfaces";
import { StatusLocales } from "shared/utils";

import styles from "./styles";

export default function Status({ type }: { type: EStatus }): JSX.Element | null {
  if (type === EStatus.FUTURE) return null;
  return (
    <styles.StatusWrapper type={type}>
      <styles.StatusText>
        <Translate value={StatusLocales[type]} />
      </styles.StatusText>
    </styles.StatusWrapper>
  );
}
