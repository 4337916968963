import Big from "big.js";

import { ITranslationKeys } from "services/translation";
import { ZERO } from "shared/constants";
import { ISale, EStatus } from "shared/interfaces";
import {
  calculateUserVesting,
  detectActiveVesting,
  getStatusTitle,
  getVestingFinanceData,
  getVestingTitle,
} from "shared/utils";

import styles from "./styles";
import Timer from "../Timer";
import Translate from "../Translate";

interface IHelperSection {
  sale: ISale;
  status: EStatus;
  depositTokenDecimals: number;
}

function ClaimWrapper({ title, allTokensClaimed }: { title: ITranslationKeys; allTokensClaimed?: boolean }) {
  return (
    <styles.ClaimWrapper allTokensClaimed={allTokensClaimed}>
      {!allTokensClaimed && <styles.Circle />}
      <Translate value={title} />
    </styles.ClaimWrapper>
  );
}

export default function HelperSection({ sale, status, depositTokenDecimals }: IHelperSection) {
  switch (status) {
    case EStatus.OPEN: {
      return <Timer title={getStatusTitle(status)} time={sale.endDate} />;
    }
    case EStatus.SOON: {
      return <Timer title={getStatusTitle(status)} time={sale.startDate} />;
    }
    case EStatus.CLIFF: {
      if (sale.cliffEndDate && sale.userData?.claimAvailable) {
        return <Timer title="Status.Cliff" time={sale.cliffEndDate} />;
      }
      return null;
    }
    case EStatus.VESTING:
    case EStatus.CLOSED: {
      if (sale.userData?.refundAvailable) return <ClaimWrapper title="Card.Refund" />;
      if (!sale.vesting || !sale.userData) return null;

      const vestingArray = calculateUserVesting(sale.vesting.arr, sale.userData);
      const { totalLocked, availableTokens, claimed } = getVestingFinanceData(
        vestingArray,
        sale.userData,
        depositTokenDecimals
      );

      if (sale.userData.claimAvailable && Big(availableTokens).gt(ZERO)) return <ClaimWrapper title="Card.Claim" />;

      const allTokensClaimed = Big(totalLocked).eq(ZERO) && Big(availableTokens).eq(ZERO) && !Big(claimed).eq(ZERO);
      if (allTokensClaimed) return <ClaimWrapper title="Card.AllTokens" allTokensClaimed={allTokensClaimed} />;

      const activeVesting = sale.userData.claimAvailable ? detectActiveVesting(vestingArray) : null;
      if (!activeVesting) return null;
      return <Timer time={activeVesting.date} title={getVestingTitle(sale.vesting.type)} />;
    }

    default: {
      return null;
    }
  }
}
