import { t } from "i18next";

import { EStatus } from "shared/interfaces";

import Timer from "../Timer";

interface IHelperText {
  status: EStatus;
  startDate: number;
  endDate: number;
}

export default function CurrentTimer({ status, startDate, endDate }: IHelperText) {
  switch (status) {
    case EStatus.SOON:
      return <Timer title="Status.LeftToStart" time={startDate} />;
    case EStatus.OPEN:
      return <Timer title="Status.LeftToFinish" time={endDate} />;
    case EStatus.FUTURE:
      return <Timer title="Status.LeftToStart" textInsteadOfTime={t("Status.ComingSoon")} />;
    default:
      return null;
  }
}
