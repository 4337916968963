import { keyStores } from "near-api-js";

import { IConfig } from "./config";
import mainnet from "./env-defaults/mainnet";
import testnet from "./env-defaults/testnet";

enum EEnvironment {
  TESTNET = "testnet",
  MAINNET = "mainnet",
}

const environments: { [key in EEnvironment]: IConfig } = {
  [EEnvironment.MAINNET]: mainnet,
  [EEnvironment.TESTNET]: testnet,
};

const currentEnvironment: EEnvironment = (process.env.REACT_APP_NEAR_ENV as EEnvironment) || EEnvironment.MAINNET;

export const {
  networkId,
  keyStore,
  nodeUrl,
  walletUrl,
  helperUrl,
  explorerUrl,
  contractId,
  wNearAddress,
  usn,
  myNearWalletUrl,
  cdnUrl,
  whitelistCdnUrl,
  saleIdsUsingLockup,
}: IConfig = {
  ...environments[currentEnvironment],
  keyStore: new keyStores.BrowserLocalStorageKeyStore(),
};
