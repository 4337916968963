import { IParticipantData } from "shared/utils/getParticipantData";

import styles from "./styles";
import Translate from "../Translate";

export default function ValueSale({ participantData }: { participantData: Array<IParticipantData> }) {
  return (
    <styles.Wrapper>
      {participantData.map(({ title, additionalTitle, amount, currency, label }) => (
        <styles.Item key={title}>
          <styles.Title>
            <Translate
              value={title}
              interpolation={{
                currency: additionalTitle,
              }}
            />
          </styles.Title>
          <styles.Data>
            <Translate
              value="Sale.PriceValue"
              interpolation={{
                price: amount,
                currency,
              }}
            />
          </styles.Data>
          {label && (
            <styles.Label>
              <Translate
                value={label.key}
                interpolation={{
                  price: label.amount,
                  currency: label.currency,
                }}
              />
            </styles.Label>
          )}
        </styles.Item>
      ))}
    </styles.Wrapper>
  );
}
