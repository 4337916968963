import { ITranslationKeys } from "services/translation";
import { EStatus, ETypeVesting } from "shared/interfaces";

export const StatusLocales: { [key: string]: ITranslationKeys } = {
  [EStatus.OPEN]: "Status.Open",
  [EStatus.SOON]: "Status.Soon",
  [EStatus.VESTING]: "Status.Closed",
  [EStatus.CLIFF]: "Status.Closed",
  [EStatus.CLOSED]: "Status.Closed",
  [EStatus.CANCELLED]: "Status.Cancelled",
};

export const StatusTimeLocales: { [key: string]: ITranslationKeys } = {
  [EStatus.OPEN]: "Status.LeftToFinish",
  [EStatus.SOON]: "Status.LeftToStart",
};

export const StatusVestingTimeLocales: { [key: string]: ITranslationKeys } = {
  [ETypeVesting.OneTime]: "Status.OneTime",
  [ETypeVesting.Stepwise]: "Status.Stepwise",
};

export const getVestingTitle = (vestingType?: ETypeVesting) => {
  switch (vestingType) {
    case ETypeVesting.OneTime:
      return StatusVestingTimeLocales[vestingType];
    case ETypeVesting.Stepwise:
      return StatusVestingTimeLocales[vestingType];
    default:
      return null;
  }
};

export const getStatusTitle = (status: EStatus) => {
  switch (status) {
    case EStatus.SOON:
      return StatusTimeLocales[status];
    case EStatus.OPEN:
      return StatusTimeLocales[status];
    default:
      return null;
  }
};
