import { Dispatch, SetStateAction } from "react";

import styles from "./styles";

interface IBurgerToggle {
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
}

export default function BurgerToggle({ isOpened, setIsOpened }: IBurgerToggle) {
  return (
    <styles.Toggle isOpen={isOpened} onClick={() => setIsOpened(!isOpened)}>
      <div>
        <span />
      </div>
      <div>
        <span />
      </div>
      <div>
        <span />
      </div>
    </styles.Toggle>
  );
}
