import Translate from "shared/components/Translate";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { selectLoading } from "store/selectors/selectLoading";

import styles from "./styles";

export default function Footer(): JSX.Element {
  const loading = useAppSelector(selectLoading);
  const year = new Date().getUTCFullYear();
  return (
    <styles.Footer isLoading={loading}>
      <p>
        <Translate value="Footer.Launchpad" />
      </p>
      <p>
        <Translate value="Footer.Copyright" interpolation={{ year: year.toString() }} />
      </p>
    </styles.Footer>
  );
}
