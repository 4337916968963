import isEqual from "lodash/isEqual";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { toFutureSale, toSale } from "routes/constant";
import { DEFAULT_FILTER } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { EStatus } from "shared/interfaces";
import { EModals, showModal } from "store/slices/modals";
import { selectSalesFilter } from "store/slices/sales";

import styles from "./styles";
import Translate from "../Translate";

interface IModalActionRow {
  loading: boolean;
}
export default function ModalActionRow({ loading }: IModalActionRow) {
  const navigate = useNavigate();
  const filter = useAppSelector(selectSalesFilter);
  const dispatch = useAppDispatch();
  const openFilterModal = () => {
    dispatch(showModal({ modal: EModals.FILTER_MODAL, props: {} }));
  };

  const openSearchModal = () => {
    dispatch(
      showModal({
        modal: EModals.SEARCH_MODAL,
        props: {
          handleConfirm: (saleId: number, status: EStatus) => {
            if (status === EStatus.FUTURE) {
              navigate(toFutureSale(saleId));
            } else {
              navigate(toSale(saleId));
            }
          },
        },
      })
    );
  };

  const isDefaultFilter = useMemo(() => isEqual(DEFAULT_FILTER, filter), [filter]);

  return (
    <styles.ActionWrapper loading={loading ? loading.toString() : undefined}>
      <styles.LogoContainer onClick={openSearchModal}>
        <styles.SearchImg />
      </styles.LogoContainer>
      <span>
        <Translate value="HomePage.Title" />
      </span>
      <styles.LogoContainer onClick={openFilterModal}>
        <styles.FilterImg />
        {!isDefaultFilter && <styles.RedDot />}
      </styles.LogoContainer>
    </styles.ActionWrapper>
  );
}
