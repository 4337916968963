import styled from "styled-components";

const DomainsWrapper = styled.div<{ isOpened: boolean }>`
  display: ${({ isOpened }) => (isOpened ? "none" : "flex")};
`;

const BlockchainDomainButton = styled.a<{ isLoading: boolean }>`
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  padding: 0.563rem;
  background: ${({ theme }) => theme.opacityGrey};
  color: ${({ theme, isLoading }) => (isLoading ? theme.transparent : theme.grey)};
  border-radius: 1rem;
  text-decoration: none;
  transition: all 0.3s;
  & > svg {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.5rem;
    path {
      fill: ${({ theme, isLoading }) => (isLoading ? theme.transparent : theme.grey)};
    }
  }
  :last-child {
    margin-right: 0;
  }
  :hover {
    color: ${({ theme, isLoading }) => (isLoading ? theme.transparent : theme.black)};
    background: ${({ theme }) => theme.white};
    svg {
      path {
        fill: ${({ theme, isLoading }) => (isLoading ? theme.transparent : theme.black)};
      }
    }
  }
  :nth-of-type(1) {
    color: ${({ theme, isLoading }) => (isLoading ? theme.transparent : theme.black)};
    background: ${({ theme }) => theme.white};
    svg {
      path {
        fill: ${({ theme, isLoading }) => (isLoading ? theme.transparent : theme.black)};
      }
    }
  }
`;

const BlockhainDomainName = styled.span`
  font-weight: 600;
  font-size: 0.75rem;
`;

export default {
  DomainsWrapper,
  BlockchainDomainButton,
  BlockhainDomainName,
};
