import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-top: 3rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1.5rem;
  `}
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      width: unset;
      flex: 50%
    `}
  :nth-child(n+3) {
    ${({ theme }) => theme.mediaWidth.upToSmall`
      margin-top: 1.5rem;
    `}
  }
  :nth-of-type(3n + 0) {
    text-align: right;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: left;
    `}
  }
  :nth-of-type(2),
  :nth-of-type(5) {
    text-align: center;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: right;
    `}
  }
  :nth-last-child(-n + 3) {
    padding-top: 0.75rem;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      padding-top: 0;
      text-align: right;
    `}
  }
  div:nth-child(4n + 2),
  div:nth-child(4n + 3) {
    ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: right;
    `}
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: left;
    `}
  :nth-last-child(2) {
    ${({ theme }) => theme.mediaWidth.upToSmall`
      text-align: left;
    `}
  }
`;

const Title = styled.span`
  color: ${({ theme }) => theme.primaryGrey};
  font-size: 0.75rem;
`;

const Data = styled.span`
  color: ${({ theme }) => theme.black};
  font-weight: 600;
  font-size: 1rem;
  padding-top: 0.375rem;
`;

const Label = styled.span`
  color: ${({ theme }) => theme.primaryGrey};
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.938rem;
  padding-top: 0.25rem;
`;

export default {
  Wrapper,
  Item,
  Title,
  Data,
  Label,
};
