import { EFilterByStatus, EFilterByParticipation, EFilterByResult } from "shared/interfaces";

export const DEFAULT_PAGE_LIMIT = 100;
export const ONE_YOCTO_NEAR = "0.000000000000000000000001";

export const ONE_SECOND = 1000;
export const UPDATE_SALE_INTERVAL = 3 * ONE_SECOND;
export const MICRO_SECOND = 1000 * ONE_SECOND;
export const ONE_MINUTE = 60 * ONE_SECOND;

export const DEFAULT_DECIMALS = 18;
export const STORAGE_TO_REGISTER_FT = "0.1";
export const STORAGE_TO_REGISTER_WNEAR = "0.00125";
export const DEFAULT_GAS = "100000000000000";
export const CLAIM_PURCHASED_GAS = "200000000000000";
export const NEAR_TOKEN_ID = "near";
export const NEAR_DECIMALS = 24;
export const ZERO = "0";
export const ONE = "1";
export const EMPTY_STRING = "";

export enum EDimensions {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
  UNKNOWN = "UNKNOWN",
}

export const MEDIA_WIDTHS = {
  upToSmall: 600,
  upToMedium: 768,
  upToLarge: 1280,
};

export const NUMBER_PLACEHOLDER_CARD = Array.from(Array(5).keys());
export const NUMBER_PLACEHOLDER_ROW = Array.from(Array(6).keys());
export const NUMBER_PLACEHOLDER_VALUE = Array.from(Array(2).keys());

export const TIMESTAMP = {
  ONE_DAY: 60 * 60 * 24,
  ONE_HOUR: 60 * 60,
  ONE_MINUTE: 60,
};

export const DEFAULT_FILTER = {
  status: EFilterByStatus.ALL,
  participation: EFilterByParticipation.ALL,
  result: EFilterByResult.ALL,
};

export const ACCOUNT_TRIM_LENGTH = 12;

export const DECIMAL_PREFIX_KILO = 1000;
export const ONE_HUNDRED = 100;
export const radiusProgressCircle = 7.335;

export const TEST_CDN_ID = "TEST_CDN_ID";

export const linkOptions = {
  blank: "_blank",
  noreferrer: "noreferrer",
};

export const lockupURL = "https://lockup.dev/app";
// eslint-disable-next-line max-len
export const lockupGuideOnMediumURL =
  "https://medium.com/lockup-dapp/lockup-empowering-decentralized-fund-distribution-and-vesting-contracts-ebacbe96bf59";
