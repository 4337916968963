import { FungibleTokenContract } from "services/contract";
import { ESaleType } from "services/interfaces";
import { IUserWhitelistData } from "shared/utils/getUserWhitelistStatus";

import { IFilter } from "./index";
import { IUserData } from "./user";
import { VestingProps } from "./vesting";

export enum EStatus {
  SOON = "SOON",
  OPEN = "OPEN",
  CLIFF = "CLIFF",
  VESTING = "VESTING",
  CLOSED = "CLOSED",
  CANCELLED = "CANCELLED",
  FUTURE = "FUTURE",
}

export enum ESocial {
  Medium,
  Telegram,
  Twitter,
}

export interface ISocials {
  value: string | null;
  type: ESocial;
}

interface SaleMetadata {
  name: string;
  depositSymbol: string;
  description: string;
  projectUrl: string;
  rewardTokenLogo: string;
  rewardTicker: string;
  rewardDescription: string | null;
  socials: Array<{ value: string | null; type: ESocial }>;
}

export interface ISale {
  id: number;
  metadata: SaleMetadata;
  depositTokenId: string;
  claimAvailable: boolean;
  refundAvailable: boolean;
  distributeTokenId: string | null;
  distributeTokenDecimals: number | null;
  minBuy: string;
  maxBuy: string;
  totalAmount: string;
  startDate: number;
  endDate: number;
  rewardDate?: number;
  price: string;
  limitPerTransaction: string;
  collectedAmount: string;
  numAccountSales: number;
  saleType: ESaleType;
  claimBegun: boolean;
  status: EStatus;
  filter: IFilter;

  userData?: IUserData;
  vesting: VestingProps | null;
  cliffEndDate: number | null;
  whitelistHash: Array<number> | null;
}

export interface SaleTransactionProps {
  token: FungibleTokenContract;
  saleId: number;
}

export interface IDepositSaleData extends SaleTransactionProps {
  amount: string;
  userWhitelistData?: IUserWhitelistData;
}

export interface IFutureSale {
  id: number;
  metadata: SaleMetadata;
  status: EStatus;
  depositTokenId: string;
  distributeTokenId: string;
}
