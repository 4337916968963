import { ReactComponent as Circle } from "assets/images/icons/circle-icon.svg";
import { ReactComponent as Claimed } from "assets/images/icons/claimed-icon.svg";
import { ReactComponent as NotClaimed } from "assets/images/icons/not-claimed-icon.svg";
import { EVestingStatus } from "shared/interfaces";

import ProgressCircle from "../ProgressCircle";

interface ICurrentLogo {
  status: EVestingStatus;
  startPeriod?: number;
  endPeriod: number;
}

export default function CurrentLogo({ status, startPeriod, endPeriod }: ICurrentLogo) {
  switch (status) {
    case EVestingStatus.EMPTY:
      return <Claimed />;
    case EVestingStatus.AVAILABLE_TO_CLAIM:
      return <NotClaimed />;

    default: {
      if (status === EVestingStatus.ACTIVE && startPeriod) {
        return <ProgressCircle startPeriod={startPeriod} endPeriod={endPeriod} />;
      }
      return <Circle />;
    }
  }
}
