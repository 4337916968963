import styled from "styled-components";

import { Skeleton } from "shared/components/Placeholder/Skeleton";

const Container = styled.div`
  padding: 1rem;
  box-shadow: 0 0.25rem 0.5rem -0.25rem ${({ theme }) => theme.shadowWrapper};
  border-radius: 1rem;
  margin-top: 1.5rem;
  width: 100%;
  max-height: 14.125rem;
  background: ${({ theme }) => theme.white};
  :first-child {
    margin-top: 0;
  }
`;

const ImageContainer = styled(Skeleton)`
  height: 3rem;
  width: 100%;
  border-radius: 0.25rem;
`;

const SideWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1.375rem;
  div {
    :last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;

const Title = styled(Skeleton)`
  border-radius: 0.25rem;
  width: 8.063rem;
  height: 0.75rem;
`;

const IconRow = styled.div`
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
`;

const Icon = styled(Skeleton)`
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
`;

const Stage = styled(Skeleton)`
  width: 3.938rem;
  height: 0.5rem;
  border-radius: 0.25rem;
`;

const PriceName = styled(Skeleton)`
  margin-top: 1.25rem;
  width: 2.688rem;
  height: 0.5rem;
  border-radius: 0.25rem;
`;

const PriceValue = styled(Skeleton)`
  margin-top: 0.75rem;
  width: 4.875rem;
  height: 0.75rem;
  border-radius: 0.25rem;
`;

export default function SmallSkeleton() {
  return (
    <Container>
      <ImageContainer />
      <SideWrapper>
        <div>
          <Title />
          <IconRow>
            <Icon />
            <Stage />
          </IconRow>
          <PriceName />
          <PriceValue />
        </div>
        <div>
          <PriceName />
          <PriceValue />
        </div>
      </SideWrapper>
    </Container>
  );
}
