import Big from "big.js";
import { useEffect } from "react";

import { ESaleType } from "services/interfaces";
import { ONE_SECOND } from "shared/constants";
import { EStatus, ISale, IVesting } from "shared/interfaces";
import { updateSaleStatus } from "store/slices/sales";

import { useAppDispatch } from "./redux/useAppDispatch";

const validateTypeSoon = (startDate: number): EStatus => {
  const currentTimestamp = Date.now();
  if (startDate < currentTimestamp) {
    return EStatus.OPEN;
  }
  return EStatus.SOON;
};

const validateTypeOpen = (
  endDate: number,
  saleType: ESaleType,
  totalAmount: string,
  collectedAmount: string,
  cliffEndDate: number | null
): EStatus => {
  const currentTimestamp = Date.now();
  const activeCliff = cliffEndDate && cliffEndDate > currentTimestamp;
  if (saleType === ESaleType.ByAmount && Big(totalAmount).eq(collectedAmount)) {
    if (activeCliff) return EStatus.CLIFF;
    return EStatus.VESTING;
  }
  if (endDate < currentTimestamp) {
    if (activeCliff) return EStatus.CLIFF;
    return EStatus.VESTING;
  }
  return EStatus.OPEN;
};

const validateTypeCliff = (cliffEndDate?: number | null): EStatus => {
  const currentTimestamp = Date.now();
  if (cliffEndDate && cliffEndDate >= currentTimestamp) {
    return EStatus.CLIFF;
  }
  return EStatus.VESTING;
};

const validateTypeVesting = (vesting?: Array<IVesting>): EStatus => {
  const currentTimestamp = Date.now();
  const vestingEndDate = vesting?.[vesting.length - 1]?.date;
  if (vestingEndDate && vestingEndDate < currentTimestamp) {
    return EStatus.CLOSED;
  }
  return EStatus.VESTING;
};

export const useSynchronizer = (sales: Array<ISale>) => {
  const dispatch = useAppDispatch();
  return useEffect(() => {
    if (!sales.length) return;
    const updater = () => {
      sales.forEach((sale) => {
        switch (sale.status) {
          case EStatus.SOON: {
            dispatch(updateSaleStatus({ id: sale.id, status: validateTypeSoon(sale.startDate) }));
            return;
          }
          case EStatus.OPEN: {
            dispatch(
              updateSaleStatus({
                id: sale.id,
                status: validateTypeOpen(
                  sale.endDate,
                  sale.saleType,
                  sale.totalAmount,
                  sale.collectedAmount,
                  sale.cliffEndDate
                ),
              })
            );
            return;
          }
          case EStatus.CLIFF: {
            dispatch(updateSaleStatus({ id: sale.id, status: validateTypeCliff(sale.cliffEndDate) }));
            return;
          }
          case EStatus.VESTING: {
            dispatch(updateSaleStatus({ id: sale.id, status: validateTypeVesting(sale.vesting?.arr) }));
            return;
          }
        }
      });
    };
    const interval = setInterval(updater, ONE_SECOND);
    return () => clearInterval(interval);
  }, [dispatch, sales]);
};
