import { IConfig } from "../config";

export default {
  networkId: "mainnet",
  nodeUrl: "https://rpc.mainnet.near.org",
  walletUrl: "https://wallet.near.org",
  helperUrl: "https://helper.mainnet.near.org",
  explorerUrl: "https://explorer.mainnet.near.org",
  contractId: "launchpad.bocachica_mars.near",
  wNearAddress: "wrap.near",
  usn: "usn",
  myNearWalletUrl: "https://app.mynearwallet.com/",
  cdnUrl: "https://bocachica-static.fra1.digitaloceanspaces.com/img/mars",
  whitelistCdnUrl: (id: number) =>
    `https://bocachica-static.fra1.digitaloceanspaces.com/img/mars/${id}/merkle_leaves.json`,
  saleIdsUsingLockup: [64],
} as IConfig;
