import styled from "styled-components";

import { hoverTransition } from "shared/theme/transitions";

const SocialNetworksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SocialNetworksIcon = styled.a<{ isCardPage?: boolean }>`
  ${({ isCardPage }) => (isCardPage ? "margin-left: 2rem;" : "margin-right: 2rem;")}

  & > svg {
    width: 2rem;
    height: 2rem;
    transition: ${hoverTransition};

    &:hover {
      opacity: 0.8;
    }
  }

  ${({ theme, isCardPage }) => theme.mediaWidth.upToSmall`
    margin: ${isCardPage ? "0 1rem" : "0 2rem 0 0"};
  `}
`;

export default {
  SocialNetworksWrapper,
  SocialNetworksIcon,
};
