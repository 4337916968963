import Big from "big.js";

import { VestingOutput } from "services/interfaces";
import { ONE_HUNDRED, ZERO } from "shared/constants";
import { ETypeVesting, EVestingStatus, IVesting, IUserData, VestingProps } from "shared/interfaces";

import { parseDate } from "./calculationDate";
import { displayAmount } from "./displayAmount";
import { nanosecondsToMilliseconds, prefixKiloToSI, roundTotalLocked } from "./formatAmount";

export const formatVesting = (vesting: Array<VestingOutput>): Array<VestingOutput> => {
  return vesting.map((el) => {
    const date = nanosecondsToMilliseconds(el.date);
    const quota = prefixKiloToSI(el.quota);
    return {
      ...el,
      quota,
      date,
    };
  });
};

export const getVestingType = (vesting: VestingOutput[]): ETypeVesting =>
  vesting && vesting.length === 1 && vesting.every((el) => el.steps === 1)
    ? ETypeVesting.OneTime
    : ETypeVesting.Stepwise;

export const detectedVestingStatus = (vestingDate: number, previousVestingDate?: number): EVestingStatus => {
  const currentDate = Date.now();
  if (!previousVestingDate && vestingDate > currentDate) return EVestingStatus.ACTIVE;
  if (previousVestingDate && previousVestingDate < currentDate && vestingDate > currentDate)
    return EVestingStatus.ACTIVE;
  if (previousVestingDate && previousVestingDate > currentDate) return EVestingStatus.SOON;
  return EVestingStatus.CLOSED;
};

export const getVestingData = (vesting: Array<VestingOutput>, saleEndDate: number): VestingProps => {
  const vestingType = getVestingType(vesting);
  switch (vestingType) {
    case ETypeVesting.OneTime: {
      const vestingArray = vesting.map((el) => {
        const dateObj = parseDate(el.date);
        return {
          ...el,
          dateObj,
          amount: ZERO,
          status: detectedVestingStatus(el.date),
        };
      });
      return { arr: vestingArray, type: vestingType };
    }
    default: {
      const vestingDate = vesting.map((el) => el.date);
      const vestingArray: IVesting[] = vesting
        .map((vestingItem, index) => {
          const previousVestingDate = vestingDate[index - 1] ? vestingDate[index - 1] : saleEndDate;
          if (vestingItem.steps > 1) {
            const progressionDifference = Math.ceil(vestingDate[index] - previousVestingDate) / vestingItem.steps;
            const quotaPerStep = vestingItem.quota / vestingItem.steps;
            let i = previousVestingDate;
            const newElement: IVesting[] = [];
            for (let stepIndex = 0; stepIndex < vestingItem.steps; stepIndex += 1) {
              const date = i + progressionDifference;
              const newDateObj = parseDate(date);
              newElement.push({
                previousVestingDate: i,
                steps: 1,
                quota: quotaPerStep,
                date,
                dateObj: newDateObj,
                amount: ZERO,
                status: detectedVestingStatus(date, i),
              });
              i += progressionDifference;
            }
            return newElement;
          }
          const dateObj = parseDate(vestingItem.date);
          return {
            ...vestingItem,
            previousVestingDate,
            dateObj,
            amount: ZERO,
            status: detectedVestingStatus(vestingItem.date, previousVestingDate),
          };
        })
        .flat()
        .filter((el) => el.quota);
      return { arr: vestingArray, type: vestingType };
    }
  }
};

export const getUserTokenAmounts = (
  totalLocked: string,
  availableTokens: string,
  claimed: string,
  depositTokenDecimals: number
) => [
  {
    title: "Sale.Vesting.TitleLocked",
    value: displayAmount(totalLocked, depositTokenDecimals),
  },
  {
    title: "Sale.Vesting.AvailableTokens",
    value: displayAmount(availableTokens, depositTokenDecimals),
  },
  {
    title: "Sale.Vesting.Claimed",
    value: displayAmount(claimed, depositTokenDecimals),
  },
];

export const calculateUserVesting = (vestingArray: Array<IVesting>, userData: IUserData): Array<IVesting> => {
  let claimedAmount = userData.claimed;
  return vestingArray.map((vesting) => {
    const amount = Big(userData.purchase).mul(vesting.quota).div(ONE_HUNDRED).toFixed();
    const status = detectedVestingStatus(vesting.date, vesting.previousVestingDate);
    switch (status) {
      case EVestingStatus.CLOSED: {
        if (!Big(claimedAmount).lte(ZERO)) {
          claimedAmount = Big(claimedAmount).minus(amount).toFixed();
          return {
            ...vesting,
            amount,
            status: EVestingStatus.EMPTY,
          };
        }
        return {
          ...vesting,
          amount,
          status: EVestingStatus.AVAILABLE_TO_CLAIM,
        };
      }
      default:
        return {
          ...vesting,
          amount,
          status,
        };
    }
  });
};

export const detectActiveVesting = (vestingArray: Array<IVesting>): IVesting | undefined =>
  vestingArray.find((el) => el.status === EVestingStatus.ACTIVE);

export const getCliffDetails = (formattedVesting: VestingOutput[] | null): number | null => {
  if (!formattedVesting) return null;
  const cliff = formattedVesting[0] || null;
  if (!cliff || formattedVesting[0].quota !== 0) return null;
  return cliff.date;
};

export const getVestingFinanceData = (
  vestingArray: Array<IVesting>,
  userData: IUserData,
  depositTokenDecimals: number
) => {
  const { claimed } = userData;
  const availableTokens = vestingArray.reduce((acc: string, vestingItem) => {
    if (vestingItem.status === EVestingStatus.AVAILABLE_TO_CLAIM) {
      return Big(vestingItem.amount).plus(acc).toFixed();
    }
    return acc;
  }, ZERO);
  const totalLocked = Big(userData.purchase).minus(availableTokens).minus(claimed).toFixed();
  const roundedTotalLocked = roundTotalLocked(totalLocked, depositTokenDecimals);

  return {
    totalLocked: roundedTotalLocked,
    availableTokens,
    claimed,
  };
};
