import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DEFAULT_FILTER } from "shared/constants";
import { IFilter } from "shared/interfaces/filter";
import { EStatus, ISale, IFutureSale } from "shared/interfaces/sale";
import { RootState } from "store";

type SalesState = {
  obj: Record<number, ISale>;
  future: Record<number, IFutureSale>;
  loading: boolean;
  filter: IFilter;
};

const initialState: SalesState = {
  obj: {},
  future: {},
  loading: true,
  filter: DEFAULT_FILTER,
};

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    updateSaleStatus(state, action: PayloadAction<{ id: number; status: EStatus }>) {
      state.obj[action.payload.id].status = action.payload.status;
    },
    updateSale(state, action: PayloadAction<ISale>) {
      state.obj[action.payload.id] = action.payload;
    },
    setSales(state, action: PayloadAction<Record<string, ISale>>) {
      state.obj = action.payload;
    },
    setFutureSales(state, action: PayloadAction<Record<string, IFutureSale>>) {
      state.future = action.payload;
    },
    setSalesLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    updateFilter: (state, action: PayloadAction<IFilter>) => {
      state.filter = action.payload;
    },
  },
});

// SELECTORS
export const selectSales = (state: RootState) => state.sales.obj;
export const selectSalesLoading = (state: RootState) => state.sales.loading;
export const selectSalesFilter = (state: RootState) => state.sales.filter;
export const selectFutureSales = (state: RootState) => state.sales.future;

// ACTIONS
export const { updateSaleStatus, updateSale, setSalesLoading, setSales, updateFilter, setFutureSales } =
  salesSlice.actions;

export const salesReducer = salesSlice.reducer;
