import { motion } from "framer-motion";
import styled from "styled-components";

import { hoverTransition } from "shared/theme/transitions";

const ShareDropdown = styled(motion.div)`
  right: 0;
  z-index: 10;
  position: absolute;
  min-width: 16.875rem;
  border-radius: 0.5rem;
  top: calc(100% + 0.75rem);
  background-color: ${({ theme }) => theme.grayOp01};

  > div {
    padding: 0.5rem;
  }
`;

const ShareButton = styled(motion.div)`
  gap: 0.5rem;
  display: flex;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  height: 2.375rem;
  padding: 0 0.25rem;
  align-items: center;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.black};
  transition: ${hoverTransition};

  &:hover {
    background-color: ${({ theme }) => theme.greyDark};
  }

  svg {
    width: 2rem;
    height: auto;

    path {
      fill: ${({ theme }) => theme.black};
    }
  }
`;

export default {
  ShareDropdown,
  ShareButton,
};
