import { useNavigate } from "react-router-dom";

import { toSale } from "routes/constant";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { ISale, EStatus } from "shared/interfaces";
import { formatTokenAmount } from "shared/utils";
import { selectToken } from "store/selectors/selectToken";

import HelperSection from "./HelperSection";
import styles from "./styles";
import SaleLayoutInfo from "../SaleLayoutInfo";
import Translate from "../Translate";

interface ICard {
  sale: ISale;
}

export default function Card({ sale }: ICard) {
  const navigate = useNavigate();
  const { status } = sale;
  const token = useAppSelector((state) => selectToken(state, sale.depositTokenId));
  if (!token?.metadata) return null;
  const depositTokenPrice = formatTokenAmount(sale.price, token.metadata.decimals);
  const formatTotalAmount = formatTokenAmount(sale.totalAmount, token.metadata.decimals);

  return (
    <styles.Container onClick={() => navigate(toSale(sale.id))} isOpen={status === EStatus.OPEN}>
      <SaleLayoutInfo
        name={sale.metadata.name}
        tokenIcon={token.metadata.icon}
        currency={token.metadata.symbol}
        saleType={sale.saleType}
        status={status}
        saleId={sale.id}
      />
      <styles.Footer>
        <styles.SaleInfoWrapper>
          <styles.SaleInfoBlock>
            <p>
              <Translate value="Sale.Price" interpolation={{ currency: sale.metadata.rewardTicker }} />
            </p>
            <p>
              <Translate
                value="Sale.PriceValue"
                interpolation={{
                  price: depositTokenPrice,
                  currency: token.metadata.symbol,
                }}
              />
            </p>
          </styles.SaleInfoBlock>
          <styles.SaleInfoBlock>
            <p>
              <Translate value="Sale.Target" />
            </p>
            <p>
              <Translate
                value="Sale.PriceValue"
                interpolation={{
                  price: formatTotalAmount,
                  currency: token.metadata.symbol,
                }}
              />
            </p>
          </styles.SaleInfoBlock>
        </styles.SaleInfoWrapper>
        <styles.RightSide>
          <HelperSection sale={sale} status={status} depositTokenDecimals={token.metadata.decimals} />
          <styles.ArrowLogoContainer>
            <styles.ArrowIcon />
          </styles.ArrowLogoContainer>
        </styles.RightSide>
      </styles.Footer>
    </styles.Container>
  );
}
