import styled from "styled-components";

import { ReactComponent as SocialIcon } from "assets/images/icons/social.svg";
import { hoverTransition } from "shared/theme/transitions";

const LayoutParent = styled.div`
  position: relative;
  height: 3rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    overflow: hidden;
  `}
`;

const ProjectLayoutImg = styled.img`
  width: 100%;
  height: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  border-radius: 0.25rem;
`;

const ProjectInfoNaming = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: flex-start;
  justify-content: space-between;
`;

const ProjectName = styled.p`
  font-weight: 600;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.black};
`;

const StageRow = styled.div`
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  span {
    :nth-of-type(2) {
      margin: 0 0.5rem;
    }
  }
`;

const StageIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.813rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0px 2px 4px ${({ theme }) => theme.blackOp015};
  align-self: center;
`;

const SocialImg = styled(SocialIcon)`
  width: 1.25rem;
  cursor: pointer;
  height: 1.438rem;
  transition: ${hoverTransition};

  &:hover {
    opacity: 0.8;
  }
`;

const StageTxt = styled.div`
  color: ${({ theme }) => theme.primaryGrey};
  font-size: 0.75rem;
`;

const StatusRow = styled.div`
  display: flex;
  gap: 1.125rem;
  position: relative;
`;

export default {
  LayoutParent,
  ProjectLayoutImg,
  ProjectInfoNaming,
  ProjectName,
  StageRow,
  StageIcon,
  StageTxt,
  StatusRow,
  SocialImg,
};
