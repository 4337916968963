import { createSelector } from "@reduxjs/toolkit";

import { FungibleTokenContract } from "services/contract";
import { RootState } from "store";
import { selectTokens } from "store/slices/tokens";

export const selectToken = createSelector(
  [selectTokens, (_: RootState, id: string) => id],
  (tokens, id): FungibleTokenContract | null => {
    const token = tokens[id] || null;
    return token;
  }
);
