import { FinalExecutionOutcome } from "@near-wallet-selector/core";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";

import { contractId, wNearAddress } from "services/config";
import { SaleContract } from "services/contract";
import { Action } from "services/interfaces";
import RPCProviderService from "services/RPCProviderService";
import ToastService from "shared/components/Toast";
import { IDepositSaleData } from "shared/interfaces";
import { RootState } from "store";

export const deposit = createAsyncThunk<
  void,
  {
    provider: RPCProviderService;
    requestSignTransactions: (t: Action[]) => Promise<void | FinalExecutionOutcome[]>;
    depositData: IDepositSaleData;
  },
  { state: RootState }
>("sales/deposit", async ({ provider, requestSignTransactions, depositData }, { getState }) => {
  try {
    const state = getState();
    const accountId = state.user.id;
    const saleContract = new SaleContract(contractId, provider, accountId);
    const actions: Action[] = [];
    const { token, amount, saleId, userWhitelistData } = depositData;
    const isJoined = await saleContract.hasAccount();
    const proofsWhitelisted =
      userWhitelistData &&
      userWhitelistData.userIndex !== null &&
      userWhitelistData.userIndex > -1 &&
      userWhitelistData.usersCount &&
      userWhitelistData.proofs
        ? {
            userIndex: userWhitelistData.userIndex,
            countUsers: userWhitelistData.usersCount,
            proofs: userWhitelistData.proofs,
          }
        : undefined;

    const message = proofsWhitelisted
      ? JSON.stringify({
          sale_id: saleId,
          proofs_whitelisted: {
            user_index: proofsWhitelisted.userIndex,
            count_users: proofsWhitelisted.countUsers,
            proofs: proofsWhitelisted.proofs,
          },
        })
      : `{"sale_id": ${saleId}}`;

    if (!isJoined) {
      const joinFee = await saleContract.getJoinFee();
      if (!joinFee) return;
      const join = saleContract.join({ joinFee });
      actions.push(...join);
    }
    if (token.contractId === wNearAddress) {
      const depositNear = await token.depositNear({ amount, saleId, proofsWhitelisted });
      actions.push(...depositNear);
    } else {
      const depositFTToken = await token.transfer({ accountId, amount, message });
      actions.push(...depositFTToken);
    }
    await requestSignTransactions(actions);
  } catch (error) {
    ToastService.error(t("Toast.Deposit"));
    console.error("Error during user deposit: ", error);
  }
});
