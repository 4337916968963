import Big from "big.js";
import { formatNearAmount } from "near-api-js/lib/utils/format";

import nearIcon from "assets/images/icons/near-token-icon.svg";
import { contractId, usn, wNearAddress } from "services/config";
import { ITokenMetadata, Action, IStorageBalance, FungibleTokenContractInterface } from "services/interfaces";
import { IRPCProviderService } from "services/RPCProviderService/interfaces";
import {
  NEAR_DECIMALS,
  NEAR_TOKEN_ID,
  ONE_YOCTO_NEAR,
  STORAGE_TO_REGISTER_FT,
  STORAGE_TO_REGISTER_WNEAR,
  ZERO,
} from "shared/constants";
import { parseTokenAmount } from "shared/utils";

import { FTChangeMethods, FTViewMethods } from "./contractMethods";

const NEAR_TOKEN: ITokenMetadata = {
  decimals: NEAR_DECIMALS,
  icon: nearIcon,
  name: "Near token",
  version: "0",
  symbol: "NEAR",
  reference: "",
};

export default class FungibleTokenContract {
  readonly contractId: string;

  private provider: IRPCProviderService;

  metadata: ITokenMetadata | null = null;

  constructor(props: FungibleTokenContractInterface) {
    this.provider = props.provider;
    this.contractId = props.contractId;
  }

  async getMetadata() {
    try {
      if (this.contractId === wNearAddress) {
        this.metadata = { ...NEAR_TOKEN };
        return NEAR_TOKEN;
      }
      const metadata = await this.provider.viewFunction(FTViewMethods.ftMetadata, this.contractId);
      if (!metadata) return null;

      this.metadata = { ...metadata };
      return metadata;
    } catch (e) {
      console.warn(`Error while loading ${this.contractId}`);
      return null;
    }
  }

  async getBalanceOf({ accountId }: { accountId: string }): Promise<string> {
    try {
      if (this.contractId === wNearAddress) {
        const account = await this.provider.viewAccount(accountId);
        return account?.amount || ZERO;
      }
      const balance = await this.provider.viewFunction(FTViewMethods.ftBalanceOf, this.contractId, {
        account_id: accountId,
      });
      return balance || ZERO;
    } catch (error) {
      return ZERO;
    }
  }

  async getMinStorageBalanceBounce(): Promise<string> {
    try {
      const storageBalanceBounce = await this.provider.viewFunction(
        FTViewMethods.storageBalanceBounds,
        this.contractId
      );
      return storageBalanceBounce?.min || STORAGE_TO_REGISTER_FT;
    } catch (e) {
      console.warn(`Error: ${e} while you try to get min storage balance bounce.`);
      return ZERO;
    }
  }

  async getStorageBalance({ accountId }: { accountId: string }): Promise<IStorageBalance | undefined> {
    return this.provider.viewFunction(FTViewMethods.storageBalanceOf, this.contractId, { account_id: accountId });
  }

  async checkStorageBalance({ accountId }: { accountId: string }): Promise<Action[]> {
    try {
      if (this.contractId === NEAR_TOKEN_ID || this.contractId === usn) return [];
      const storageBalance = await this.getStorageBalance({ accountId });
      const minStorageBalanceBounds = await this.getMinStorageBalanceBounce();
      if (
        !storageBalance ||
        (!Big(minStorageBalanceBounds).eq(ZERO) && Big(storageBalance.total).lt(minStorageBalanceBounds))
      ) {
        const defaultStorageAmount =
          this.contractId === wNearAddress ? STORAGE_TO_REGISTER_WNEAR : STORAGE_TO_REGISTER_FT;

        let storageAmount = defaultStorageAmount;
        if (Big(minStorageBalanceBounds).gt(storageBalance?.total || ZERO)) {
          const newStorageAmount = Big(minStorageBalanceBounds)
            .minus(storageBalance?.total || ZERO)
            .toFixed();
          const formattedAmount = formatNearAmount(newStorageAmount);
          storageAmount = formattedAmount;
        }

        return [
          {
            receiverId: this.contractId,
            functionCalls: [
              {
                methodName: FTChangeMethods.storageDeposit,
                args: {
                  registration_only: true,
                  account_id: accountId,
                },
                amount: storageAmount,
              },
            ],
          },
        ];
      }
      return [];
    } catch (e) {
      return [];
    }
  }

  async transfer({
    accountId,
    amount,
    message,
  }: {
    accountId: string;
    amount: string;
    message: string;
  }): Promise<Action[]> {
    const transactions: Action[] = [];
    if (!this.metadata) return [];
    const checkStorage = await this.checkStorageBalance({ accountId });
    transactions.push(...checkStorage);
    const formattedAmount = parseTokenAmount(amount, this.metadata.decimals);

    transactions.push({
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: FTChangeMethods.ftTransferCall,
          args: {
            receiver_id: contractId,
            amount: formattedAmount,
            msg: message,
            memo: amount,
          },
          amount: ONE_YOCTO_NEAR,
        },
      ],
    });
    return transactions;
  }

  async depositNear({
    amount,
    saleId,
    proofsWhitelisted,
  }: {
    amount: string;
    saleId: number;
    proofsWhitelisted?: {
      userIndex: number;
      countUsers: number;
      proofs: Array<Array<number>>;
    };
  }): Promise<Action[]> {
    if (!this.metadata) return [];
    return [
      {
        receiverId: contractId,
        functionCalls: [
          {
            methodName: FTChangeMethods.depositNear,
            args: {
              sale_deposit: {
                sale_id: saleId,
                proofs_whitelisted: proofsWhitelisted
                  ? {
                      user_index: proofsWhitelisted.userIndex,
                      count_users: proofsWhitelisted.countUsers,
                      proofs: proofsWhitelisted.proofs,
                    }
                  : undefined,
              },
            },
            amount,
          },
        ],
      },
    ];
  }
}
