import Big from "big.js";

import { ESaleType } from "services/interfaces";
import { ONE_SECOND, TIMESTAMP } from "shared/constants";
import { IParseDate, ITimeLeft, EStatus, IVesting } from "shared/interfaces";

export const detectTimestamp = (startDate: number, endDate: number, status: EStatus) => {
  if (status === EStatus.SOON) {
    return startDate;
  }
  return endDate;
};

const formatDate = (number: number, isDay?: boolean) => {
  if (isDay) {
    return number.toString();
  }
  return number > 9 ? number.toString() : `0${number}`;
};

export const formatCountdown = (diffInMilliSeconds: number): ITimeLeft => {
  const diffInSeconds = Math.floor(diffInMilliSeconds / ONE_SECOND);
  if (diffInSeconds <= 1) {
    return {
      days: formatDate(0, true),
      hours: formatDate(0),
      minutes: formatDate(0),
      seconds: formatDate(0),
    };
  }
  const days = Math.floor(diffInSeconds / TIMESTAMP.ONE_DAY);
  const hours = Math.floor((diffInSeconds - days * TIMESTAMP.ONE_DAY) / TIMESTAMP.ONE_HOUR);
  const minutes = Math.floor(
    (diffInSeconds - days * TIMESTAMP.ONE_DAY - hours * TIMESTAMP.ONE_HOUR) / TIMESTAMP.ONE_MINUTE
  );
  const seconds =
    diffInSeconds - days * TIMESTAMP.ONE_DAY - hours * TIMESTAMP.ONE_HOUR - minutes * TIMESTAMP.ONE_MINUTE;
  return {
    days: formatDate(days, true),
    hours: formatDate(hours),
    minutes: formatDate(minutes),
    seconds: formatDate(seconds),
  };
};

export const parseDate = (date: string | number): IParseDate => {
  const newDate = new Date(date);
  const month = newDate.toLocaleString("en", { month: "short" });
  return {
    year: newDate.getFullYear(),
    month,
    day: formatDate(newDate.getDate(), true),
    hours: formatDate(newDate.getHours()),
    minutes: formatDate(newDate.getMinutes()),
  };
};

export const getCurrentStatus = (
  startDate: number,
  endDate: number,
  totalAmount: string,
  collectedAmount: string,
  saleType: ESaleType,
  cliffEndDate: number | null,
  vesting?: Array<IVesting> | null
): EStatus => {
  const currentTimestamp = Date.now();
  const saleHasNotStartedYet = startDate > currentTimestamp;
  const activeCliff = cliffEndDate && cliffEndDate > currentTimestamp;
  const saleEndedByTime = endDate < currentTimestamp;
  const saleEndedByCondition = saleType === ESaleType.ByAmount && Big(totalAmount).eq(collectedAmount);
  const isEndedSale = saleEndedByCondition || saleEndedByTime;
  const isActiveSale = currentTimestamp > startDate && !isEndedSale;

  if (saleType === ESaleType.Cancelled) return EStatus.CANCELLED;
  if (saleHasNotStartedYet) return EStatus.SOON;
  if (isActiveSale) return EStatus.OPEN;

  if (isEndedSale) {
    if (activeCliff) return EStatus.CLIFF;
    return EStatus.VESTING;
  }

  const vestingStartDate = vesting?.[0]?.date;
  if (vestingStartDate && vestingStartDate < currentTimestamp) return EStatus.VESTING;

  const vestingEndDate = vesting?.[vesting.length - 1]?.date;
  if (vestingEndDate && vestingEndDate < currentTimestamp) return EStatus.CLOSED;
  return EStatus.CLOSED;
};
