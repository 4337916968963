import { useEffect, useState } from "react";

import { ONE_SECOND } from "shared/constants";
import { EVestingStatus, IUserData, IVesting } from "shared/interfaces";
import { detectActiveVesting, calculateUserVesting } from "shared/utils";

const useVesting = (vesting: Array<IVesting>, userData: IUserData) => {
  const [vestingArray, setVestingArray] = useState<Array<IVesting>>(calculateUserVesting(vesting, userData));

  const initialVestingEnded = vestingArray.every(
    ({ status }) =>
      status === EVestingStatus.CLOSED ||
      status === EVestingStatus.AVAILABLE_TO_CLAIM ||
      status === EVestingStatus.EMPTY
  );

  const [timestamp, setTimestamp] = useState<number>(detectActiveVesting(vestingArray)?.date || 0);
  const [vestingEnded, setVestingEnded] = useState<boolean>(initialVestingEnded);
  const [lastCheck, setLastCheck] = useState<boolean>(false);

  useEffect(() => {
    const updateStatus = () => {
      const activeVesting = detectActiveVesting(vestingArray);
      const shouldUpdate = (vestingEnded && !lastCheck) || (activeVesting && activeVesting.date <= Date.now());
      if (!shouldUpdate) return;
      const updatedVestingArray = calculateUserVesting(vesting, userData);
      const updatedActiveVesting = detectActiveVesting(updatedVestingArray);
      if (updatedActiveVesting) {
        setVestingArray(updatedVestingArray);
        setTimestamp(updatedActiveVesting.date);
      } else {
        const updatedVestingEnded = updatedVestingArray.every((el) => el.date < Date.now());
        setVestingEnded(updatedVestingEnded);
        setVestingArray(updatedVestingArray);
        setLastCheck(true);
      }
    };
    const interval = setInterval(updateStatus, ONE_SECOND);
    return () => clearInterval(interval);
  }, [userData, vesting, vestingEnded, lastCheck, vestingArray]);

  return {
    timestamp,
    vestingEnded,
    vestingArray,
  };
};

export default useVesting;
