import { formatNearAmount } from "near-api-js/lib/utils/format";

import { Action, ISaleAccount, SaleOutput } from "services/interfaces";
import RPCProviderService from "services/RPCProviderService";
import { IRPCProviderService } from "services/RPCProviderService/interfaces";
import { CLAIM_PURCHASED_GAS } from "shared/constants";

import { SaleChangeMethods, SaleViewMethods } from "./contractMethods";
import FungibleTokenContract from "./FungibleToken";

export default class SaleContract {
  readonly contractId: string;

  readonly accountId: string;

  private provider: IRPCProviderService;

  public constructor(contractId: string, provider: RPCProviderService, accountId: string) {
    this.contractId = contractId;
    this.provider = provider;
    this.accountId = accountId;
  }

  async getNumSales(): Promise<number | undefined> {
    return this.provider.viewFunction(SaleViewMethods.getNumSales, this.contractId);
  }

  async getSales(from: number, limit: number) {
    return this.provider.viewFunction(SaleViewMethods.getSales, this.contractId, { from_index: from, limit });
  }

  async getSaleAccount(saleId: number): Promise<ISaleAccount | undefined> {
    return this.provider.viewFunction(SaleViewMethods.getSaleAccount, this.contractId, {
      sale_id: saleId,
      account_id: this.accountId,
    });
  }

  async getSale(saleId: number): Promise<SaleOutput | undefined> {
    return this.provider.viewFunction(SaleViewMethods.getSale, this.contractId, {
      sale_id: saleId,
    });
  }

  async getJoinFee(): Promise<string | undefined> {
    return this.provider.viewFunction(SaleViewMethods.getJoinFee, this.contractId);
  }

  async hasAccount(accountId: string = this.accountId): Promise<boolean | undefined> {
    return this.provider.viewFunction(SaleViewMethods.hasAccount, this.contractId, { account_id: accountId });
  }

  join({ joinFee }: { joinFee: string }): Action[] {
    return [
      {
        receiverId: this.contractId,
        functionCalls: [
          {
            methodName: SaleChangeMethods.join,
            args: {},
            amount: formatNearAmount(joinFee) as string,
          },
        ],
      },
    ];
  }

  async claimRefund({ saleId, token }: { saleId: number; token: FungibleTokenContract }): Promise<Action[]> {
    const actions: Action[] = [];
    const checkStorage = await token.checkStorageBalance({ accountId: this.accountId });
    actions.push(...checkStorage);
    actions.push({
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.claimRefund,
          args: {
            sale_id: saleId,
          },
        },
      ],
    });
    return actions;
  }

  async claimPurchased({ saleId, token }: { saleId: number; token: FungibleTokenContract }): Promise<Action[]> {
    const actions: Action[] = [];
    const checkStorage = await token.checkStorageBalance({ accountId: this.accountId });
    actions.push(...checkStorage);
    actions.push({
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: SaleChangeMethods.claimPurchased,
          args: {
            sale_id: saleId,
          },
          gas: CLAIM_PURCHASED_GAS,
        },
      ],
    });
    return actions;
  }

  async getNumFutureSales() {
    return this.provider.viewFunction(SaleViewMethods.getNumFutureSales, this.contractId);
  }

  async getFutureSales(from: number, limit: number) {
    return this.provider.viewFunction(SaleViewMethods.getFutureSales, this.contractId, { from_index: from, limit });
  }

  async getFutureSale(futureSaleId: number) {
    return this.provider.viewFunction(SaleViewMethods.getFutureSale, this.contractId, {
      future_sale_id: futureSaleId,
    });
  }
}
