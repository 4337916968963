import Big from "big.js";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useWalletData } from "providers/WalletSelectorProvider";
import { ROUTES } from "routes/constant";
import AmountProgress from "shared/components/AmountProgress";
import DepositSection from "shared/components/DepositSection";
import { Metadata } from "shared/components/Metadata";
import BigSkeleton from "shared/components/Placeholder/BigSkeleton";
import RefundSection from "shared/components/RefundSection";
import SaleDate from "shared/components/SaleDate";
import SaleLayoutInfo from "shared/components/SaleLayoutInfo";
import SocialNetwork from "shared/components/SocialNetwork";
import Timer from "shared/components/Timer";
import ToastService from "shared/components/Toast";
import ValueSale from "shared/components/ValueSale";
import VestingSchedule from "shared/components/VestingSchedule";
import VestingSection from "shared/components/VestingSection";
import { EMPTY_STRING, UPDATE_SALE_INTERVAL, ZERO } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { EStatus, EWhitelistedStatus } from "shared/interfaces";
import { colors } from "shared/theme";
import { EDepositErrors, formatSale, validateValue } from "shared/utils";
import { claim } from "store/actions/claim";
import { deposit } from "store/actions/deposit";
import { formatUserDataForSale, createBalanceState, getToken } from "store/actions/helpers";
import { loadWhitelist } from "store/actions/loadWhitelist";
import { refund } from "store/actions/refund";
import { selectData } from "store/selectors/selectData";
import { selectSaleData } from "store/selectors/selectSaleData";
import { selectWhitelistBySale } from "store/selectors/selectWhitelistBySale";
import { updateSale } from "store/slices/sales";
import { updateTokenBalance } from "store/slices/user";

import styles from "./styles";

export default function SalePage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { RPCProvider, requestSignTransactions, saleContract } = useWalletData();
  const { tokens, user, loading } = useAppSelector(selectData);

  const [isAssent, setIsAssent] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>(EMPTY_STRING);
  const [error, setError] = useState<EDepositErrors | null>(null);

  const sale = useAppSelector((state) => selectSaleData(state, Number(id)));
  const isAccountConnected = user.isSignedIn;

  const userWhitelistData = useAppSelector((state) => selectWhitelistBySale(state, Number(id)));

  useEffect(() => {
    if (!user.id || !id) return;

    dispatch(loadWhitelist({ saleId: Number(id) }));
  }, [user.id, id, sale]);

  useEffect(() => {
    const updateData = async () => {
      if (!sale || sale.status !== EStatus.OPEN || !sale.token.metadata) return;
      const { balance } = sale;
      try {
        const saleOutput = await saleContract.getSale(sale.id);
        if (!saleOutput) return;
        let currentSale = formatSale(saleOutput);
        const isCollectedAmountNotChanged = currentSale.collectedAmount === sale.collectedAmount;
        if (isAccountConnected) {
          const [retrievedBalance, saleDataByUser] = await Promise.all([
            sale.token.getBalanceOf({ accountId: user.id }),
            saleContract.getSaleAccount(sale.id),
          ]);
          const isSaleNotUpdated = isCollectedAmountNotChanged && balance === retrievedBalance;
          const isUserNotJoined = !Big(saleDataByUser?.amount || ZERO).gt(ZERO);
          if (isSaleNotUpdated || isUserNotJoined) return;
          currentSale = formatUserDataForSale(currentSale, saleDataByUser, sale.token.metadata.decimals);
          const balanceState = createBalanceState(sale.token.contractId, sale.token.metadata, retrievedBalance);
          dispatch(updateTokenBalance({ id: sale.token.contractId, balance: balanceState }));
          dispatch(updateSale(currentSale));
        } else if (!isCollectedAmountNotChanged) {
          dispatch(updateSale(currentSale));
        }
      } catch (e) {
        console.warn(`Error: ${e} while update sale data`);
      }
    };
    const interval = setInterval(updateData, UPDATE_SALE_INTERVAL);
    return () => clearInterval(interval);
  }, [tokens, saleContract, isAccountConnected, user.id, user.balances, dispatch, sale]);

  if (loading || !sale || !sale.token?.metadata || isEmpty(userWhitelistData)) return <BigSkeleton />;
  if (!loading && !sale) navigate(ROUTES.HOME);
  const {
    balance,
    showDepositSection,
    showVestingSchedule,
    completedSale,
    isLockupSale,
    participantData,
    showDistributionIn,
  } = sale;

  const makeDeposit = () => {
    if (!sale || !sale.token?.metadata) return;
    const validateAmount = validateValue({
      isAssent,
      value: amount,
      balance: balance || ZERO,
      min: sale.minBuy,
      max: sale.maxBuy,
      deposited: sale.userData?.deposited || ZERO,
      decimal: sale.token.metadata.decimals,
      limitPerTransaction: sale.limitPerTransaction,
      isButtonClick: true,
      refund: sale.userData?.refund || ZERO,
    });
    setError(validateAmount);
    if (validateAmount) return;
    ToastService.loading("Toast.Deposit");
    const whitelistedUser = userWhitelistData.status === EWhitelistedStatus.WHITELISTED;
    const depositData = {
      token: sale.token,
      amount,
      saleId: sale.id,
      userWhitelistData: whitelistedUser ? userWhitelistData : undefined,
    };

    dispatch(deposit({ provider: RPCProvider, requestSignTransactions, depositData }));
  };

  const makeRefund = () => {
    if (!sale || !sale.token) return;
    ToastService.loading("Toast.ClaimRefund");
    const transactionProps = { saleId: sale.id, token: sale.token };
    dispatch(refund({ provider: RPCProvider, requestSignTransactions, transactionProps }));
  };

  const makeClaim = () => {
    if (!sale) return;
    ToastService.loading("Toast.ClaimPurchase");
    const distributeToken = getToken(sale.distributeTokenId || EMPTY_STRING, tokens);
    if (!distributeToken) return;
    const transactionProps = { saleId: sale.id, token: distributeToken };
    dispatch(claim({ provider: RPCProvider, requestSignTransactions, transactionProps }));
  };

  return (
    <styles.Container>
      <Metadata title={sale.metadata.name} description={sale.metadata.description} />
      <styles.Wrapper>
        <styles.CloseBtn onClick={() => navigate(ROUTES.HOME)} />
        <SaleLayoutInfo
          currency={sale.token.metadata.symbol}
          isSalePage
          name={sale.metadata.name}
          tokenIcon={sale.token.metadata.icon}
          saleType={sale.saleType}
          status={sale.status}
          saleId={sale.id}
        />
        {showDepositSection && (
          <DepositSection
            amount={amount}
            balance={balance}
            token={sale.token}
            error={error}
            isAssent={isAssent}
            sale={sale}
            setAmount={setAmount}
            setError={setError}
            status={sale.status}
            setIsAssent={setIsAssent}
            makeDeposit={makeDeposit}
            isUserWhitelisted={userWhitelistData.status}
          />
        )}
        {sale.userData && sale.userData.refundAvailable && (
          <RefundSection
            currency={sale.token.metadata.symbol}
            depositTokenDecimals={sale.token.metadata.decimals}
            makeRefund={makeRefund}
            refundValue={sale.userData.refund}
          />
        )}
        {sale.cliffEndDate && showDistributionIn && (
          <styles.DistributionInContainer>
            <Timer title="Status.Cliff" time={sale.cliffEndDate} />
          </styles.DistributionInContainer>
        )}
        {sale.vesting && sale.userData && sale.userData.claimAvailable && completedSale && (
          <VestingSection
            depositTokenDecimals={sale.token.metadata.decimals}
            userData={sale.userData}
            rewardTicker={sale.metadata.rewardTicker}
            vesting={sale.vesting}
            makeClaim={makeClaim}
            isLockupSale={isLockupSale}
          />
        )}
        <styles.InfoWrapper>
          <ValueSale participantData={participantData} />
          <AmountProgress
            collectedAmount={sale.collectedAmount}
            currency={sale.token.metadata.symbol}
            depositTokenDecimals={sale.token.metadata.decimals}
            totalAmount={sale.totalAmount}
          />
          <SaleDate endDate={sale.endDate} startDate={sale.startDate} />
        </styles.InfoWrapper>
        {sale.vesting && showVestingSchedule && <VestingSchedule vesting={sale.vesting} />}
        <styles.Description>{sale.metadata.description}</styles.Description>
        <styles.SocialWrapper>
          <styles.SocialBox href={sale.metadata.projectUrl}>
            <p>{sale.metadata.name}</p>
            <styles.ExternalLink />
          </styles.SocialBox>
          <SocialNetwork socials={sale.metadata.socials} color={colors.black} isCardPage />
        </styles.SocialWrapper>
      </styles.Wrapper>
    </styles.Container>
  );
}
