import Big from "big.js";

import { calcUserData } from "shared/calculation";
import { ZERO } from "shared/constants";
import { EWhitelistedStatus, ISale } from "shared/interfaces";
import { displayBalance, EDepositErrors, ErrorsMap, parseTokenAmount } from "shared/utils";

import styles from "./styles";
import Translate from "../Translate";

interface IHelperText {
  amount: string;
  rewardTicker: string;
  depositTokenDecimals: number;
  error: EDepositErrors | null;
  sale: ISale;
  isUserWhitelisted: EWhitelistedStatus;
}

export default function HelperText({
  amount,
  rewardTicker,
  depositTokenDecimals,
  error,
  sale,
  isUserWhitelisted,
}: IHelperText) {
  if (error) {
    return (
      <styles.Error>
        <styles.ErrorIcon />
        <Translate value={ErrorsMap[error]} />
      </styles.Error>
    );
  }
  const parseAmount = parseTokenAmount(amount || ZERO, depositTokenDecimals);
  const deposited = Big(sale.userData?.deposited || ZERO)
    .plus(sale.userData?.refund || ZERO)
    .plus(parseAmount)
    .toFixed();

  const { purchase } = calcUserData({
    price: sale.price,
    totalAmount: sale.totalAmount,
    deposited,
    collectedAmount: Big(sale.collectedAmount).plus(parseAmount).toFixed(),
    saleType: sale.saleType,
    depositTokenDecimals,
  });
  const formatPurchase = displayBalance(purchase, depositTokenDecimals);
  const finalAllocation = Big(purchase).eq(ZERO) ? "" : formatPurchase;
  return (
    <styles.HelperText>
      {isUserWhitelisted === EWhitelistedStatus.NOT_WHITELISTED ? (
        <Translate value="Sale.NotWhitelisted" />
      ) : (
        <>
          <Translate value="Sale.FinalAllocation" />
          &nbsp;
          <strong>{`- ${finalAllocation} ${rewardTicker}`}</strong>
        </>
      )}
    </styles.HelperText>
  );
}
