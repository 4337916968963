const FUTURE = "future";

export const ROUTES = {
  HOME: "/",
  SALE_BY_ID: "/:id",
  FUTURE_SALE_BY_ID: `/${FUTURE}/:id`,
  DEFAULT: "*",
};

export const toSale = (id: number) => ROUTES.HOME + id;
export const toFutureSale = (id: number) => `/${FUTURE}/${id}`;
