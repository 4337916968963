import styled from "styled-components";

import { ReactComponent as Wallet } from "assets/images/icons/wallet.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
`;

const FieldBox = styled.div`
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
`;

const TokenAccountRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.black};
  font-size: 0.75rem;
  margin: 0 0.625rem 0.375rem;
`;

const WalletIcon = styled(Wallet)`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  path {
    fill: ${({ theme }) => theme.grey};
  }
`;

const TokenBalance = styled.div`
  display: flex;
  align-items: center;
`;

const MaxValueBtn = styled.button`
  border: unset;
  outline: unset;
  background: transparent;
  font-weight: 600;
  color: ${({ theme }) => theme.grey};
  cursor: pointer;
  padding: 0;
  :disabled {
    cursor: not-allowed;
  }
`;

const FieldWrapper = styled.div`
  margin-top: 0.375rem;
  position: relative;
  & > input[type="text"] {
    -moz-appearance: textfield;
  }
  & > input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledInput = styled.input<{ valid?: boolean }>`
  color: ${({ theme }) => theme.black};
  text-transform: uppercase;
  background-color: unset;
  padding-left: 1rem;
  padding-right: 6rem;
  width: 100%;
  outline: none;
  border: 1px solid ${({ theme, valid }) => (valid ? theme.grey : theme.red)};
  border-radius: 0.5rem;
  height: 3rem;
  font-size: 1rem;
  transition: box-shadow 0.2s, border 0.2s;
  :focus-within,
  hover {
    border: 1px solid ${({ theme }) => theme.orange};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.boxShadowInput};
  }
  :placeholder {
    color: ${({ theme }) => theme.black};
  }
  :disabled {
    cursor: not-allowed;
  }
`;

const Currency = styled.span`
  color: ${({ theme }) => theme.black};
  font-size: 1rem;
  margin-right: 1rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  user-select: none;
`;

export default {
  Wrapper,
  FieldBox,
  TokenAccountRow,
  FieldWrapper,
  StyledInput,
  WalletIcon,
  TokenBalance,
  MaxValueBtn,
  Currency,
};
