import { ISocials } from "shared/interfaces";
import { ImageMap } from "shared/utils";

import styles from "./styles";

export interface ISocialNetwork {
  socials: ISocials[];
  color: string;
  isCardPage?: boolean;
}

export default function SocialNetwork({ socials, color, isCardPage }: ISocialNetwork) {
  const socialsLength = socials.length;

  return (
    <styles.SocialNetworksWrapper>
      {socialsLength &&
        socials.map(({ value, type }) => {
          if (!value) return null;
          const Image = ImageMap[type];
          return (
            <styles.SocialNetworksIcon
              key={`${type}: ${value}`}
              href={value}
              target="_blank"
              rel="noreferrer"
              isCardPage={isCardPage}
            >
              <Image fill={color} />
            </styles.SocialNetworksIcon>
          );
        })}
    </styles.SocialNetworksWrapper>
  );
}
