import styled from "styled-components";

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  max-width: 43rem;
  width: 100%;
  transition: all 1s ease;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0 2rem;
    max-width: 100%;
  `}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 1rem;
  `}
`;

export const WrapperCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.75rem;
`;

export default {
  Container,
  WrapperCards,
};
