import { IConfig } from "../config";

export default {
  networkId: "testnet",
  nodeUrl: "https://rpc.testnet.near.org",
  walletUrl: "https://wallet.testnet.near.org",
  helperUrl: "https://helper.testnet.near.org",
  explorerUrl: "https://explorer.testnet.near.org",
  contractId: "bocadmin.testnet",
  wNearAddress: "wrap.testnet",
  usn: "usdn.testnet",
  myNearWalletUrl: "https://testnet.mynearwallet.com/",
  cdnUrl: "https://bocachica-static.fra1.digitaloceanspaces.com/img/mars",
  whitelistCdnUrl: () => "https://bocachica-static.fra1.digitaloceanspaces.com/img/mars/staging/merkle_leaves.json",
  saleIdsUsingLockup: [62],
} as IConfig;
