import { ReactComponent as Facebook } from "assets/images/social-icons/facebook.svg";
import { ReactComponent as Telegram } from "assets/images/social-icons/telegram-icon.svg";
import { ReactComponent as Twitter } from "assets/images/social-icons/twitter-icon.svg";

export const socialShare = [
  {
    name: "Twitter",
    icon: <Twitter />,
    link: (path: string) => `https://twitter.com/intent/tweet?url=${path}`,
  },
  {
    name: "Facebook",
    icon: <Facebook />,
    link: (path: string) => `https://www.facebook.com/sharer.php?u=${path}`,
  },
  {
    name: "Telegram",
    icon: <Telegram />,
    link: (path: string) => `https://telegram.me/share/url?url=${path}`,
  },
];
