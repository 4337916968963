import buffer from "buffer";

import { NetworkId, setupWalletSelector, Transaction } from "@near-wallet-selector/core";
import type { WalletSelector, AccountState } from "@near-wallet-selector/core";
import { setupModal } from "@near-wallet-selector/modal-ui";
import type { WalletSelectorModal } from "@near-wallet-selector/modal-ui";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import myNearWalletIconUrl from "@near-wallet-selector/my-near-wallet/assets/my-near-wallet-icon.png";
import nearWalletIconUrl from "@near-wallet-selector/near-wallet/assets/near-wallet-icon.png";
import { setupSender } from "@near-wallet-selector/sender";
import senderWalletIconUrl from "@near-wallet-selector/sender/assets/sender-icon.png";
import { providers } from "near-api-js";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { map, distinctUntilChanged } from "rxjs";

import { contractId, networkId, myNearWalletUrl } from "services/config";
import { SaleContract } from "services/contract";
import { parseTransactions } from "services/helpers/receiptsService";
import { Action } from "services/interfaces";
import RPCProviderService from "services/RPCProviderService";
import { IRPCProviderService } from "services/RPCProviderService/interfaces";
import { EMPTY_STRING } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { getAmount, getGas } from "shared/utils";
import { selectAccountId, setAccountId, setIsSignedIn } from "store/slices/user";

import { WalletContextType } from "./interfaces";

window.Buffer = window.Buffer || buffer.Buffer;

declare global {
  interface Window {
    selector: WalletSelector;
    modal: WalletSelectorModal;
  }
}
const ACCOUNT_ID = "accountId";

const WalletSelectorContext = createContext<WalletContextType>({} as WalletContextType);

export function WalletSelectorProvider({ children }: { children: JSX.Element }) {
  const dispatch = useAppDispatch();
  const accountId = useAppSelector(selectAccountId);

  const [selector, setSelector] = useState<WalletSelector | null>(null);
  const [modal, setModal] = useState<WalletSelectorModal | null>(null);
  const [RPCProvider, setRPCProvider] = useState<IRPCProviderService>(new RPCProviderService());
  const [lastTransaction, setLastTransaction] = useState<number>(Date.now());
  const saleContract = useMemo(() => new SaleContract(contractId, RPCProvider, accountId), [RPCProvider, accountId]);

  const syncAccountState = (currentAccountId: string | null, newAccounts: Array<AccountState>) => {
    if (!newAccounts.length) {
      localStorage.removeItem(ACCOUNT_ID);
      dispatch(setAccountId(EMPTY_STRING));
      return;
    }

    const validAccountId = currentAccountId && newAccounts.some((x) => x.accountId === currentAccountId);
    const newAccountId = validAccountId ? currentAccountId : newAccounts[0].accountId;

    localStorage.setItem(ACCOUNT_ID, newAccountId);
    dispatch(setAccountId(newAccountId));
  };

  const init = useCallback(async () => {
    const selectorInstance = await setupWalletSelector({
      network: networkId as NetworkId,
      debug: true,
      modules: [
        setupMyNearWallet({ iconUrl: myNearWalletIconUrl, walletUrl: myNearWalletUrl }),
        setupSender({ iconUrl: senderWalletIconUrl }),
      ],
    });
    const modalInstance = setupModal(selectorInstance, { contractId });
    const state = selectorInstance.store.getState();
    syncAccountState(localStorage.getItem(ACCOUNT_ID), state.accounts);

    window.selector = selectorInstance;
    window.modal = modalInstance;

    const { network } = selectorInstance.options;
    const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });
    const providerService = new RPCProviderService(provider);
    const isSignedIn = selectorInstance.isSignedIn();
    setRPCProvider(providerService);
    setSelector(selectorInstance);
    setModal(modalInstance);
    dispatch(setIsSignedIn(isSignedIn));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    init().catch((err) => {
      console.error(err);
    });
  }, [init]);

  useEffect(() => {
    if (!selector) {
      return;
    }

    const subscription = selector.store.observable
      .pipe(
        map((state) => {
          const result = state.accounts;
          return result;
        }),
        distinctUntilChanged()
      )
      .subscribe((nextAccounts) => {
        syncAccountState(accountId, nextAccounts);
      });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector, accountId]);

  const requestSignTransactions = useCallback(
    async (transactions: Action[]) => {
      if (!selector) return console.warn("No wallet selected");

      const nearTransactions: Transaction[] = transactions.map((transaction: Action) => ({
        signerId: accountId,
        receiverId: transaction.receiverId,
        actions: transaction.functionCalls.map((fc) => ({
          type: "FunctionCall",
          params: {
            methodName: fc.methodName,
            args: fc.args || {},
            gas: getGas(fc.gas),
            deposit: getAmount(fc.amount),
          },
        })),
      }));

      const walletInstance = await selector.wallet();
      const result = await walletInstance.signAndSendTransactions({ transactions: nearTransactions });

      if (result) {
        parseTransactions(result);
      }
      setLastTransaction(Date.now());
      return result;
    },
    [accountId, selector]
  );

  const openModal = useCallback(() => {
    if (!modal) return;
    modal.show();
  }, [modal]);

  const signOut = useCallback(async () => {
    try {
      if (!selector) return;
      const wallet = await selector.wallet();

      await wallet.signOut();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }, [selector]);

  const walletData = useMemo(
    () => ({
      openModal,
      requestSignTransactions,
      RPCProvider,
      signOut,
      lastTransaction,
      saleContract,
    }),
    [openModal, requestSignTransactions, RPCProvider, signOut, lastTransaction, saleContract]
  );

  if (!selector || !modal) {
    return null;
  }

  return <WalletSelectorContext.Provider value={walletData}>{children}</WalletSelectorContext.Provider>;
}

export function useWalletData() {
  return useContext(WalletSelectorContext);
}
