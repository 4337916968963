import { IParseDate } from "./index";

export interface IVesting {
  steps: number;
  quota: number;
  amount: string;
  date: number;
  dateObj: IParseDate;
  status: EVestingStatus;
  previousVestingDate?: number;
}

export enum ETypeVesting {
  OneTime = "OneTime",
  Stepwise = "Stepwise",
}

// Soon -> Active -> Closed -> AvailableToClaim -> Empty
export enum EVestingStatus {
  SOON = "SOON",
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  AVAILABLE_TO_CLAIM = "AVAILABLE_TO_CLAIM",
  EMPTY = "EMPTY",
}

export interface VestingProps {
  arr: Array<IVesting>;
  type: ETypeVesting;
}
