import styled from "styled-components";

import backgroundDesktop from "assets/images/refund-banner/banner-bg-desktop.svg";
import backgroundMobile from "assets/images/refund-banner/banner-bg-mobile.svg";
import backgroundTablet from "assets/images/refund-banner/banner-bg-tablet.svg";

const BannerWrapper = styled.div`
  z-index: 11;
  display: grid;
  align-items: center;
  padding: 0.5rem 2rem;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
  background: url(${backgroundDesktop}), linear-gradient(91.7deg, #3c75e3 0%, #ff5924 100%);
  background-repeat: no-repeat;
  background-position: 55% center;

  ${({ theme }) =>
    theme.mediaWidth.upToMedium`
        background: url(${backgroundTablet}), linear-gradient(91.7deg, #3c75e3 0%, #ff5924 100%);
        background-repeat: no-repeat;
        background-position: 55% center;
      `};

  ${({ theme }) =>
    theme.mediaWidth.upToSmall`
        padding: 0.5rem 1rem;
        background: url(${backgroundMobile}), linear-gradient(91.7deg, #3c75e3 0%, #ff5924 100%);
        background-repeat: no-repeat;
        background-position: 55% center;
      `};
`;

const BannerTitle = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1875rem;
`;

const NearSolanaWrapper = styled.div`
  gap: 15.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 899px) {
    gap: 3.5rem;
  }

  ${({ theme }) =>
    theme.mediaWidth.upToSmall`
        gap: 0.125rem;
        position: relative;

        &:after {
          content: "";
          z-index: 1;
          width: 1.5rem;
          height: 1.5rem;
          position: absolute;
          border-radius: 50%;
          border: 2px solid ${theme.white};
        }

        div {
          z-index: 2;
          &:first-child {
            margin-bottom: 1rem;
          }
          
          &:last-child {
            margin-top: 1rem;
          }
        }
      `};
`;

const BlockchainWrapper = styled.div`
  gap: 0.5rem;
  display: flex;
  font-weight: 600;
  line-height: 1rem;
  font-size: 0.75rem;
  align-items: center;
  text-transform: uppercase;
`;

const Participate = styled.div`
  button {
    gap: 0.5rem;
    height: 2.3rem;
    padding: 0.5rem;
    font-weight: 600;
    line-height: 1rem;
    font-size: 0.75rem;
    margin: 0 0 0 auto;
    border-radius: 0.5rem;
    border: 2px solid #f0aea1;
    background-color: transparent;
  }
`;

const styles = {
  BannerWrapper,
  BannerTitle,
  NearSolanaWrapper,
  Participate,
  BlockchainWrapper,
};

export default styles;
