import styled, { keyframes } from "styled-components";

import { colors } from "shared/theme";

const skeletonLoading = keyframes`
    100% {
      background-color: ${colors.opacityGreyDark};
    }

    0% {
      background-color: ${colors.opacityGrey};
    }
`;

export const Skeleton = styled.div`
  animation: ${skeletonLoading} 1s linear infinite alternate;
`;
