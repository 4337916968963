import { createSelector } from "@reduxjs/toolkit";

import { saleIdsUsingLockup } from "services/config";
import { ZERO } from "shared/constants";
import { EStatus, ISale } from "shared/interfaces";
import { getParticipantData } from "shared/utils";
import { RootState } from "store";
import { selectSales } from "store/slices/sales";
import { selectTokens } from "store/slices/tokens";
import { selectBalances } from "store/slices/user";

export const selectSaleData = createSelector(
  [selectSales, selectBalances, selectTokens, (_: RootState, saleId: number) => saleId],
  (sales, balances, tokens, saleId) => {
    const sale: ISale | null = sales[saleId] || null;
    if (!sale) return null;
    const token = tokens[sale.depositTokenId] || null;
    if (!token?.metadata) return null;
    const balance = balances[sale.depositTokenId]?.balance || ZERO;

    const { status } = sale;
    const showDepositSection = status === EStatus.SOON || status === EStatus.OPEN;
    const showDistributionIn = sale.status === EStatus.CLIFF && sale.cliffEndDate && sale.userData?.claimAvailable;
    const showVestingSchedule = showDepositSection || showDistributionIn;
    const completedSale = status === EStatus.CLOSED || status === EStatus.VESTING;
    const isLockupSale = saleIdsUsingLockup.includes(sale.id);
    const participantData = getParticipantData(sale, token.metadata.symbol, token.metadata.decimals);
    return {
      ...sale,
      token,
      balance,
      showDepositSection,
      showVestingSchedule,
      completedSale,
      isLockupSale,
      participantData,
      showDistributionIn,
    };
  }
);
