import { createAsyncThunk } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";

import { SaleContract } from "services/contract";
import { IRPCProviderService } from "services/RPCProviderService/interfaces";
import { isNotNullOrUndefined } from "shared/utils";
import { setFutureSales, setSales, setSalesLoading } from "store/slices/sales";
import { setTokens, setTokensLoading } from "store/slices/tokens";
import { setBalances } from "store/slices/user";

import {
  retrieveBalances,
  retrieveSale,
  retrieveFutureSale,
  retrieveTokensMetadata,
  retrieveUserData,
} from "./helpers";
import { RootState } from "../index";

export const initialLoading = createAsyncThunk<
  void,
  {
    provider: IRPCProviderService;
    saleContract: SaleContract;
  },
  { state: RootState }
>("sales/initialLoading", async ({ provider, saleContract }, { dispatch, getState }) => {
  try {
    const state = getState();
    const accountId = state.user.id;
    const { isSignedIn } = state.user;

    const [sales, futureSales] = await Promise.all([retrieveSale(saleContract), retrieveFutureSale(saleContract)]);

    const salesTokens = [
      ...sales.arr.flatMap((sale) => sale.depositTokenId),
      ...sales.arr.flatMap((sale) => sale.distributeTokenId),
      ...futureSales.arr.flatMap((sale) => sale.depositTokenId),
      ...futureSales.arr.flatMap((sale) => sale.distributeTokenId),
    ].filter(isNotNullOrUndefined);

    const tokenAddresses = uniq(salesTokens);
    const tokens = await retrieveTokensMetadata(tokenAddresses, provider);
    let salesObj = sales.obj;
    if (isSignedIn) {
      salesObj = await retrieveUserData(sales.arr, saleContract, tokens);
      const balances = await retrieveBalances(tokens, accountId);
      dispatch(setBalances(balances));
    }

    !isEmpty(tokens) && dispatch(setTokensLoading(false));

    dispatch(setSales(salesObj));
    dispatch(setFutureSales(futureSales.obj));
    dispatch(setTokens(tokens));
  } catch (e) {
    console.error(`Error: while initial loading sales \n ${e}`);
  } finally {
    dispatch(setSalesLoading(false));
  }
});
