import styled from "styled-components";

const Footer = styled.footer<{ isLoading: boolean }>`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
  opacity: ${({ isLoading }) => (isLoading ? "0" : "1")};
  transition: opacity 0.3s;
  & > p {
    display: flex;
    justify-content: center;
    flex: 1;
    font-weight: 400;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.primaryGrey};
    margin: 0;
  }
`;

export default {
  Footer,
};
