import styled from "styled-components";

import Buttons from "../Buttons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 12.875rem;
  background-color: ${({ theme }) => theme.lightOrange};
  color: ${({ theme }) => theme.black};
  border-radius: 1rem;
  margin-top: 2rem;
  padding: 1rem;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
`;

const Label = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.938rem;
  color: ${({ theme }) => theme.primaryGrey};
  margin-top: 2rem;
  & > span {
    color: ${({ theme }) => theme.black};
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.188rem;
    margin-top: 0.25rem;
  }
`;

const Button = styled(Buttons.Primary)`
  width: 100%;
  max-width: 23.125rem;
  height: 3rem;
  margin-top: 2rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 100%;
  `}
`;

export default {
  Container,
  Title,
  Label,
  Button,
};
