import { createAsyncThunk } from "@reduxjs/toolkit";

import { getUserWhitelistStatus } from "shared/utils/getUserWhitelistStatus";
import { selectSaleData } from "store/selectors/selectSaleData";
import { setWhitelist } from "store/slices/user";

import { RootState } from "../index";

export const loadWhitelist = createAsyncThunk<
  void,
  {
    saleId: number;
  },
  { state: RootState }
>("whitelist/loadWhitelist", async ({ saleId }, { dispatch, getState }) => {
  try {
    const state = getState();
    const userId = state.user.id;
    const sale = selectSaleData(state, saleId);

    if (!userId) dispatch(setWhitelist({}));
    if (!userId || !sale) return;

    const whitelistHash = sale.whitelistHash;
    const whitelist = await getUserWhitelistStatus(userId, whitelistHash, saleId);
    const updatedWhitelist = { ...state.user.whitelist, [saleId]: whitelist };

    dispatch(setWhitelist(updatedWhitelist));
  } catch (e) {
    console.error(`Error: while loading sale whitelist \n ${e}`);
  }
});
