import ToastService from "shared/components/Toast";

const copy = async (value: string) => {
  if ("clipboard" in navigator) {
    await navigator.clipboard
      .writeText(value)
      .then(() => ToastService.success("Toast.Copy"))
      .catch(() => ToastService.error("Toast.CopyNotAvailable"));
  } else {
    ToastService.error("Toast.CopyNotAvailable");
  }
};
export default copy;
