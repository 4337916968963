import Big from "big.js";

import { ESaleType, ISaleAccount } from "services/interfaces";
import { calcUserData } from "shared/calculation";
import { ZERO } from "shared/constants";
import { ISale, IUserData, EStatus } from "shared/interfaces";

import { formatTokenAmount, parseTokenAmount } from "./formatAmount";

const getTypeClaim = (
  sale: ISale,
  userData: ISaleAccount,
  purchase: string,
  refund: string,
  status: EStatus,
  claimed: string
): {
  claimAvailable: boolean;
  refundAvailable: boolean;
} => {
  let claimAvailable = false;
  let refundAvailable = false;
  const userParticipated = Big(userData.amount).gt(ZERO);
  const claimConditionPassed = !Big(purchase).eq(ZERO) && !Big(purchase).eq(claimed);
  const refundConditionPassed = !Big(refund).eq(ZERO) && Big(userData.refunded).eq(ZERO);

  if (status === EStatus.SOON || status === EStatus.OPEN) return { claimAvailable, refundAvailable };
  if (sale.claimAvailable && userParticipated && claimConditionPassed && sale.saleType !== ESaleType.Cancelled) {
    claimAvailable = true;
  }
  if (
    sale.refundAvailable &&
    (sale.saleType === ESaleType.BySubscription || sale.saleType === ESaleType.Cancelled) &&
    userParticipated &&
    refundConditionPassed
  ) {
    refundAvailable = true;
  }
  return { claimAvailable, refundAvailable };
};

export default function formatUserData(
  userData: ISaleAccount | undefined,
  sale: ISale,
  status: EStatus,
  depositTokenDecimals?: number
): IUserData {
  if (userData && depositTokenDecimals && sale.distributeTokenDecimals) {
    const { purchase, deposited, refund } = calcUserData({
      price: sale.price,
      totalAmount: sale.totalAmount,
      deposited: userData.amount,
      collectedAmount: sale.collectedAmount,
      saleType: sale.saleType,
      depositTokenDecimals,
    });
    const { claimAvailable, refundAvailable } = getTypeClaim(
      sale,
      userData,
      purchase,
      refund,
      status,
      userData.claimed
    );
    const formatClaimed = formatTokenAmount(userData.claimed, sale.distributeTokenDecimals);
    const parsedClaimed = parseTokenAmount(formatClaimed, depositTokenDecimals);
    const formatAmountToClaimed = formatTokenAmount(userData.amount_to_claim, sale.distributeTokenDecimals);
    const parsedAmountToClaimed = parseTokenAmount(formatAmountToClaimed, depositTokenDecimals);
    return {
      deposited,
      purchase,
      amountToClaim: parsedAmountToClaimed,
      claimed: parsedClaimed,
      refund,
      refunded: userData.refunded,
      claimAvailable,
      refundAvailable,
    };
  }
  return {
    deposited: ZERO,
    purchase: ZERO,
    amountToClaim: ZERO,
    claimed: ZERO,
    refund: ZERO,
    refunded: ZERO,
    claimAvailable: false,
    refundAvailable: false,
  };
}
