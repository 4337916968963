import { AnimatePresence } from "framer-motion";
import { Dispatch, SetStateAction } from "react";

import { ReactComponent as Copy } from "assets/images/icons/copy.svg";
import { modalVariants } from "shared/animations";
import { OutsideClickWrapper } from "shared/components/OutsideClickWrapper";
import Translate from "shared/components/Translate";
import { linkOptions } from "shared/constants";
import { socialShare } from "shared/constants/social-share";
import copy from "shared/utils/copy";

import styles from "./styles";

interface IShareDropdown {
  showShare: boolean;
  setShowShare: Dispatch<SetStateAction<boolean>>;
}

export default function ShareDropdown({ showShare, setShowShare }: IShareDropdown) {
  const location = window.location.href;

  const shareHandler = (socialLink: string) => {
    window.open(socialLink, linkOptions.blank, linkOptions.noreferrer);
    setShowShare(false);
  };

  const copyToClipboard = () => {
    copy(location);
    setShowShare(false);
  };

  return (
    <AnimatePresence>
      {showShare && (
        <styles.ShareDropdown initial="close" animate="open" variants={modalVariants} exit="close">
          <OutsideClickWrapper clickHandler={() => setShowShare(false)}>
            {socialShare.map((social) => (
              <styles.ShareButton key={social.name} onClick={() => shareHandler(social.link(location))}>
                {social.icon}
                {social.name}
              </styles.ShareButton>
            ))}
            <styles.ShareButton custom={socialShare.length} onClick={copyToClipboard}>
              <Copy />
              <Translate value="Action.CopyToClipboard" />
            </styles.ShareButton>
          </OutsideClickWrapper>
        </styles.ShareDropdown>
      )}
    </AnimatePresence>
  );
}
