import styled from "styled-components";

import { ReactComponent as Search } from "assets/images/icons/search.svg";
import { ReactComponent as Filter } from "assets/images/icons/sliders.svg";
import { hoverTransition } from "shared/theme/transitions";

const ActionWrapper = styled.div<{ loading?: string }>`
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.white};
  opacity: ${({ loading }) => (loading ? "0" : "1")};
`;

const LogoContainer = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
`;

const RedDot = styled.div`
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  right: -1px;
  border-radius: 50%;
  background: ${({ theme }) => theme.redDot};
  border: 2px solid ${({ theme }) => theme.black};
`;

const SearchImg = styled(Search)`
  transition: ${hoverTransition};

  &:hover {
    opacity: 0.8;
  }
`;

const FilterImg = styled(Filter)`
  transition: ${hoverTransition};

  &:hover {
    opacity: 0.8;
  }
`;

export default {
  ActionWrapper,
  SearchImg,
  FilterImg,
  LogoContainer,
  RedDot,
};
