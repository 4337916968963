import Big from "big.js";

import { DECIMAL_PREFIX_KILO, MICRO_SECOND } from "shared/constants";

const MILLISECONDS_IN_NANO = 6;
const BASE = 10;
Big.RM = Big.roundDown;
Big.DP = 30;

export const formatTokenAmount = (value: string, decimals: number, precision?: number): string =>
  value &&
  Big(value)
    .div(Big(BASE).pow(decimals))
    .toFixed(precision && precision);

export const parseTokenAmount = (value: string, decimals: number): string =>
  value && Big(value).times(Big(BASE).pow(decimals)).toFixed(0);

export const nanosecondsToMilliseconds = (nanoseconds: string | number): number =>
  new Big(nanoseconds).div(new Big(BASE).pow(MILLISECONDS_IN_NANO)).toNumber();

export const millisecondsToNanoseconds = (milliseconds: number) => milliseconds * MICRO_SECOND;

export const prefixKiloToSI = (quota: number) => quota / DECIMAL_PREFIX_KILO;

export const roundTotalLocked = (totalLocked: string, decimals: number) => {
  const formattedAmount = formatTokenAmount(totalLocked, decimals);
  if (Big(formattedAmount).lte("0.01")) return "0";
  return totalLocked;
};
