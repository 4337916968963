import { blockhainDomains, DomainImageMap } from "shared/utils";

import styles from "./styles";

interface IBlockhainDomains {
  loading: boolean;
  isOpened: boolean;
}

export default function BlockhainDomainsImage({ loading, isOpened }: IBlockhainDomains) {
  return (
    <styles.DomainsWrapper isOpened={isOpened}>
      {blockhainDomains.map(({ value, type, name }) => {
        const DomainImage = DomainImageMap[type];
        return (
          <styles.BlockchainDomainButton key={value} href={value} target="_blank" rel="noreferrer" isLoading={loading}>
            <DomainImage />
            <styles.BlockhainDomainName>{name}</styles.BlockhainDomainName>
          </styles.BlockchainDomainButton>
        );
      })}
    </styles.DomainsWrapper>
  );
}
