import { displayAmount } from "shared/utils";

import styles from "./styles";
import Translate from "../Translate";

interface IRefundSection {
  makeRefund: () => void;
  currency: string;
  depositTokenDecimals: number;
  refundValue: string;
}

export default function RefundSection({ currency, depositTokenDecimals, refundValue, makeRefund }: IRefundSection) {
  return (
    <styles.Container>
      <styles.Title>
        <Translate value="Sale.Refund.Title" />
      </styles.Title>
      <styles.Label>
        <Translate value="Sale.Refund.Label" />
        <span>
          <Translate
            value="Sale.PriceValue"
            interpolation={{
              price: displayAmount(refundValue, depositTokenDecimals),
              currency,
            }}
          />
        </span>
      </styles.Label>
      <styles.Button onClick={makeRefund}>
        <Translate value="Action.Claim" />
      </styles.Button>
    </styles.Container>
  );
}
