import Big from "big.js";

import { ITranslationKeys } from "services/translation";
import { ZERO } from "shared/constants";
import useVesting from "shared/hooks/useVesting";
import { IUserData } from "shared/interfaces";
import { ETypeVesting, VestingProps } from "shared/interfaces";
import { getUserTokenAmounts, getVestingFinanceData, getVestingTitle } from "shared/utils";

import LockupSection from "./components/LockupSection";
import VestingList from "./components/VestingList";
import styles from "./styles";
import Timer from "../Timer";
import Translate from "../Translate";

interface IVestingSection {
  depositTokenDecimals: number;
  userData: IUserData;
  rewardTicker: string;
  vesting: VestingProps;
  makeClaim: () => void;
  isLockupSale: boolean;
}

export default function VestingSection({
  depositTokenDecimals,
  userData,
  rewardTicker,
  vesting,
  makeClaim,
  isLockupSale,
}: IVestingSection) {
  const { timestamp, vestingEnded, vestingArray } = useVesting(vesting.arr, userData);
  const { totalLocked, availableTokens, claimed } = getVestingFinanceData(vestingArray, userData, depositTokenDecimals);

  const allClaimed = Big(totalLocked).eq(ZERO) && Big(availableTokens).eq(ZERO);
  const availableToClaim = Big(availableTokens).gt(ZERO);
  const userTokensArray = getUserTokenAmounts(totalLocked, availableTokens, claimed, depositTokenDecimals);

  if (isLockupSale)
    return (
      <LockupSection
        userTokensArray={userTokensArray}
        rewardTicker={rewardTicker}
        vestingEnded={vestingEnded}
        timestamp={timestamp}
        vestingType={vesting.type}
      />
    );

  return (
    <styles.Container>
      <styles.Title>
        <Translate value="Sale.Vesting.Title" />
      </styles.Title>
      <styles.UserInfo>
        {userTokensArray.map((el) => (
          <styles.UserInfoRow key={el.title}>
            <p>
              <Translate value={el.title as ITranslationKeys} />
            </p>
            <p>
              <Translate
                value="Sale.PriceValue"
                interpolation={{
                  price: el.value,
                  currency: rewardTicker,
                }}
              />
            </p>
          </styles.UserInfoRow>
        ))}
      </styles.UserInfo>
      {vesting.type === ETypeVesting.Stepwise && (
        <VestingList vesting={vestingArray} rewardTicker={rewardTicker} depositTokenDecimals={depositTokenDecimals} />
      )}
      {!allClaimed && (
        <styles.Button onClick={makeClaim} disabled={!availableToClaim}>
          <Translate value="Action.Claim" />
        </styles.Button>
      )}
      {!vestingEnded && <Timer title={getVestingTitle(vesting.type)} time={timestamp} hideBackground />}
    </styles.Container>
  );
}
