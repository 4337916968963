import { useWalletData } from "providers/WalletSelectorProvider";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { EStatus, EWhitelistedStatus } from "shared/interfaces";
import { EDepositErrors } from "shared/utils";
import { selectIsSignedIn } from "store/slices/user";

import styles from "./styles";
import Translate from "../Translate";

interface IHandleSaleActionButton {
  amount: string;
  status: EStatus;
  makeDeposit: () => void;
  error: EDepositErrors | null;
  isUserWhitelisted: EWhitelistedStatus;
}

export default function HandleSaleActionButton({
  amount,
  status,
  makeDeposit,
  error,
  isUserWhitelisted,
}: IHandleSaleActionButton) {
  const { openModal } = useWalletData();
  const isConnected = useAppSelector(selectIsSignedIn);
  if (isConnected) {
    const disabledDepositButton =
      (!amount && status !== EStatus.OPEN) || isUserWhitelisted === EWhitelistedStatus.NOT_WHITELISTED;
    return (
      <styles.Button disabled={disabledDepositButton || Boolean(error)} onClick={makeDeposit}>
        <styles.ArrowBtnIcon />
        <Translate value="Action.Deposit" />
      </styles.Button>
    );
  }
  return (
    <styles.Button onClick={openModal}>
      <styles.WalletIcon />
      <Translate value="Action.ConnectWallet" />
    </styles.Button>
  );
}
