import { useTranslation } from "react-i18next";

import { ITranslationKeys } from "services/translation";

const Translate: React.FC<{
  value: ITranslationKeys;
  interpolation?: object;
}> = ({ value, interpolation }) => {
  const [t] = useTranslation();
  return t(value, interpolation);
};
export default Translate;
