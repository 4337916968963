import { useState } from "react";

import { ESaleType } from "services/interfaces";
import Translate from "shared/components/Translate";
import { EDimensions } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { ESalesLogo } from "shared/interfaces";
import { EStatus } from "shared/interfaces";
import { EModals, showModal } from "store/slices/modals";

import styles from "./styles";
import { getTarget } from "./utils";
import Media from "../Media";
import ShareDropdown from "../ShareDropdown";
import Status from "../Status";

interface ISaleLayoutInfo {
  name: string;
  tokenIcon: string;
  currency: string;
  status: EStatus;
  saleId: number;
  saleType?: ESaleType;
  isSalePage?: boolean;
}

export default function SaleLayoutInfo({
  name,
  tokenIcon,
  saleType,
  status,
  currency,
  saleId,
  isSalePage,
}: ISaleLayoutInfo) {
  const dispatch = useAppDispatch();
  const dimensions = useWindowDimensions();
  const titleTarget = getTarget(saleType);
  const [showShare, setShowShare] = useState<boolean>(false);

  const openShare = () => {
    dimensions === EDimensions.SMALL
      ? dispatch(showModal({ modal: EModals.SHARE_MODAL, props: {} }))
      : setShowShare(true);
  };

  return (
    <>
      <styles.LayoutParent>
        <Media saleId={saleId} typeLogo={ESalesLogo.HEADER} />
      </styles.LayoutParent>
      <styles.ProjectInfoNaming>
        <div>
          <styles.ProjectName>{name}</styles.ProjectName>
          <styles.StageRow>
            <styles.StageIcon src={tokenIcon} />
            <styles.StageTxt>
              <span>
                <Translate value="Sale.Stage" interpolation={{ currency }} />
              </span>
              {titleTarget && (
                <>
                  <span>•</span>
                  <span>
                    <Translate value={titleTarget} />
                  </span>
                </>
              )}
            </styles.StageTxt>
          </styles.StageRow>
        </div>
        <styles.StatusRow>
          {isSalePage && <styles.SocialImg onClick={openShare} />}
          <Status type={status} />
          {isSalePage && <ShareDropdown showShare={showShare} setShowShare={setShowShare} />}
        </styles.StatusRow>
      </styles.ProjectInfoNaming>
    </>
  );
}
