import { createSelector } from "@reduxjs/toolkit";

import { IUserWhitelistData, userWhitelistInitialData } from "shared/utils/getUserWhitelistStatus";
import { RootState } from "store";
import { selectWhitelist } from "store/slices/user";

export const selectWhitelistBySale = createSelector(
  [selectWhitelist, (_: RootState, saleId: number) => saleId],
  (whitelist, saleId): IUserWhitelistData => {
    const whitelistBySale = whitelist[saleId] || userWhitelistInitialData;
    return whitelistBySale;
  }
);
