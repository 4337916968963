import Big from "big.js";
import { providers } from "near-api-js";
import { useEffect } from "react";

import { explorerUrl, nodeUrl } from "services/config";
import ToastService from "shared/components/Toast";
import { toArray } from "shared/utils";

const PROPERTY_NAME = "FunctionCall";
const TRANSACTION_HASHES = "transactionHashes";
const ERROR_CODE = "errorCode";
const ERROR_MESSAGE = "errorMessage";

const saleMethodsName: { [key: string]: string } = {
  placeBid: "ft_transfer_call",
  depositNear: "deposit_near",
  claimPurchase: "claim_purchase",
  claimRefund: "claim_refund",
  confirm: "confirm",
};

enum TransactionType {
  None = 0,
  Deposit,
  DepositNear,
  ClaimPurchase,
  ClaimRefund,
}

enum StatusType {
  None,
  SuccessValue,
  Failure,
}

const detailsTransaction = (transaction: providers.FinalExecutionOutcome, type: TransactionType) => {
  const { hash } = transaction.transaction;

  const successStatus = Object.prototype.hasOwnProperty.call(transaction.status, "SuccessValue");
  if (type === TransactionType.Deposit) {
    const successValue = (transaction.status as providers.FinalExecutionStatus).SuccessValue || "";
    const buff = Buffer.from(successValue, "base64");
    const value = buff.toString("ascii");
    const depositStatus = Big(value.replace(/"/g, "") || 0).gt(0);

    return {
      hash,
      status: depositStatus && successStatus ? StatusType.SuccessValue : StatusType.Failure,
    };
  }
  return {
    hash,
    status: successStatus ? StatusType.SuccessValue : StatusType.Failure,
  };
};

const getTransaction = (transactions: providers.FinalExecutionOutcome[]) => {
  const [transaction] = transactions.filter(
    (tx) => toArray(saleMethodsName).indexOf(tx.transaction.actions[0][PROPERTY_NAME].method_name) !== -1
  );

  switch (transaction.transaction.actions[0][PROPERTY_NAME].method_name) {
    case saleMethodsName.placeBid: {
      return { type: TransactionType.Deposit, transaction };
    }
    case saleMethodsName.depositNear: {
      return { type: TransactionType.DepositNear, transaction };
    }
    case saleMethodsName.ClaimPurchase: {
      return { type: TransactionType.ClaimPurchase, transaction };
    }
    case saleMethodsName.claimRefund: {
      return { type: TransactionType.ClaimRefund, transaction };
    }
    default: {
      return { type: TransactionType.None, transaction };
    }
  }
};

export function analyzeTransactions(transactions: providers.FinalExecutionOutcome[]): {
  type: TransactionType;
  status: StatusType;
  hash: string;
} {
  const { type, transaction } = getTransaction(transactions);
  if (!transaction || type === TransactionType.None) {
    return {
      type,
      status: StatusType.None,
      hash: "",
    };
  }
  const { hash, status } = detailsTransaction(transaction, type);
  return {
    type,
    status,
    hash,
  };
}

const clearHash = (queryParams: URLSearchParams) => {
  const url = new URL(window.location.href);
  if (queryParams.has(TRANSACTION_HASHES)) queryParams.delete(TRANSACTION_HASHES);

  if (queryParams.has(ERROR_CODE) || queryParams.has(ERROR_MESSAGE)) {
    queryParams.delete(ERROR_CODE);
    queryParams.delete(ERROR_MESSAGE);
  }
  window.history.replaceState({}, document.title, url.pathname);
};

export function parseTransactions(txs: providers.FinalExecutionOutcome[], toastId?: string | number) {
  const { type, status, hash } = analyzeTransactions(txs);
  const href = `${explorerUrl}/transactions/${hash}`;

  switch (type) {
    case TransactionType.Deposit:
      if (status === StatusType.SuccessValue) {
        if (toastId) {
          ToastService.success("Toast.Deposit", href);
        } else {
          ToastService.success("Toast.Deposit", href);
        }
      } else if (status === StatusType.Failure) {
        ToastService.error("Toast.Deposit", href);
      }
      break;
    case TransactionType.DepositNear:
      if (status === StatusType.SuccessValue) {
        ToastService.success("Toast.Deposit", href);
      } else if (status === StatusType.Failure) {
        ToastService.error("Toast.Deposit", href);
      }
      break;
    case TransactionType.ClaimPurchase:
      if (status === StatusType.SuccessValue) {
        ToastService.success("Toast.ClaimPurchase", href);
      } else if (status === StatusType.Failure) {
        ToastService.error("Toast.ClaimPurchase", href);
      }
      break;
    case TransactionType.ClaimRefund:
      if (status === StatusType.SuccessValue) {
        ToastService.success("Toast.ClaimRefund", href);
      } else if (status === StatusType.Failure) {
        ToastService.error("Toast.ClaimRefund", href);
      }
      break;
    default: {
      break;
    }
  }
}

export default function useTransactionHash(query: string | undefined, accountId: string) {
  return useEffect(() => {
    if (accountId) {
      const queryParams = new URLSearchParams(query);
      const transactions = queryParams?.get(TRANSACTION_HASHES);
      const errorCode = queryParams?.get(ERROR_CODE);
      const errorMessage = queryParams?.get(ERROR_MESSAGE);
      if (errorCode || errorMessage) {
        ToastService.error("Toast.UserRejected");
      }
      clearHash(queryParams);

      if (transactions) {
        const provider = new providers.JsonRpcProvider({ url: nodeUrl });
        try {
          Promise.all(transactions.split(",").map((txHash) => provider.txStatus(txHash, accountId))).then((res) =>
            parseTransactions(res)
          );
        } catch (e) {
          console.warn(`${e} error while loading tx`);
        }
      }
    }
  }, [query, accountId]);
}
