import styled from "styled-components";

import Buttons from "shared/components/Buttons";

const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 22.5rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 100%;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const TitleWrapper = styled.p`
  margin: 0;
  margin-bottom: 0.75rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.938rem;
  color: ${({ theme }) => theme.grey};
`;

const WrapperBtn = styled.div`
  display: flex;
  align-items: center;
`;

const FilterBtn = styled(Buttons.Primary)<{ isActive?: boolean }>`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.938rem;
  margin-right: 0.5rem;
  padding: 0 0.75rem;
  border-radius: 0.25rem;
  :disabled {
    cursor: not-allowed;
    color: ${({ theme, isActive }) => (isActive ? theme.orange : theme.white)};
    background-color: ${({ theme, isActive }) => (isActive ? theme.lightOrange : theme.orange)};
    opacity: ${({ isActive }) => (isActive ? "0.4" : "1")};
  }
`;

const ApplyBtn = styled(Buttons.Secondary)`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.938rem;
  margin: 0 0.25rem;
`;

const Footer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  & > button:first-child {
    margin-bottom: 1rem;
  }
  width: 100%;
  & > button {
    width: 100%;
    height: 100vh;
  }
`;

const ClearFilter = styled.p`
  position: absolute;
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.188;
  color: ${({ theme }) => theme.orange};
  margin-left: 0.5rem;
  cursor: pointer;
  top: 260px;
  :hover {
    color: ${({ theme }) => theme.orangeHover};
  }
  :active {
    color: ${({ theme }) => theme.orangeActive};
  }
`;

export default {
  Body,
  Wrapper,
  TitleWrapper,
  FilterBtn,
  WrapperBtn,
  ApplyBtn,
  Footer,
  ClearFilter,
};
