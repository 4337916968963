import { ReactComponent as NearIconExternal } from "assets/images/icons/refund-banner/external-link.svg";
import { ReactComponent as NearIcon } from "assets/images/icons/refund-banner/near-icon-orange.svg";
import { ReactComponent as SolanaIcon } from "assets/images/icons/refund-banner/solana-icon-blue.svg";
import Buttons from "shared/components/Buttons";
import styles from "shared/components/RefundBanner/styles";
import { EDimensions, EMPTY_STRING } from "shared/constants";
import useWindowDimensions from "shared/hooks/useWindowDimensions";

export default function RefundBanner() {
  const dimensions = useWindowDimensions();
  const isMobile = dimensions === EDimensions.SMALL;

  return (
    <styles.BannerWrapper>
      <styles.BannerTitle>Refund Sale on Machina</styles.BannerTitle>
      <styles.NearSolanaWrapper>
        <styles.BlockchainWrapper>
          <NearIcon />
          {isMobile ? EMPTY_STRING : "Near"}
        </styles.BlockchainWrapper>
        <styles.BlockchainWrapper>
          <SolanaIcon />
          {isMobile ? EMPTY_STRING : "Solana"}
        </styles.BlockchainWrapper>
      </styles.NearSolanaWrapper>
      <styles.Participate>
        <Buttons.Primary onClick={() => window.open("https://wallet-bind.bocachica.io/", "_blank")}>
          <NearIconExternal />
          Participate
        </Buttons.Primary>
      </styles.Participate>
    </styles.BannerWrapper>
  );
}
