import Card from "shared/components/Card";
import { FutureCard } from "shared/components/Card/FutureCard";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { EFilterByStatus, IFutureSale, ISale } from "shared/interfaces";
import { selectSalesFilter } from "store/slices/sales";

export const Cards = ({ future, sales }: { future: Array<IFutureSale>; sales: Array<ISale> }) => {
  const filter = useAppSelector(selectSalesFilter);
  const showFutureSales = filter.status === EFilterByStatus.ALL || filter.status === EFilterByStatus.SOON;
  return (
    <>
      {showFutureSales && future.map((sale) => <FutureCard key={`${sale.metadata.name} - ${sale.id}`} sale={sale} />)}
      {sales.map((sale) => (
        <Card key={`${sale.metadata.name} - ${sale.id}`} sale={sale} />
      ))}
    </>
  );
};
