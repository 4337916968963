import { useMemo } from "react";

import { calcProgress } from "shared/calculation";
import { displayAmount } from "shared/utils";

import styles from "./styles";
import Translate from "../Translate";

interface IAmountProgress {
  totalAmount: string;
  collectedAmount: string;
  currency: string;
  depositTokenDecimals: number;
}

export default function AmountProgress({
  totalAmount,
  collectedAmount,
  currency,
  depositTokenDecimals,
}: IAmountProgress) {
  const formattedTotalAmount = displayAmount(totalAmount, depositTokenDecimals);
  const formattedCollectedAmount = displayAmount(collectedAmount, depositTokenDecimals);
  const progress = useMemo(() => calcProgress(collectedAmount, totalAmount), [collectedAmount, totalAmount]);
  return (
    <styles.AmountBlock>
      <styles.Title>
        <Translate value="Sale.Progress" />
      </styles.Title>
      <progress value={formattedCollectedAmount} max={formattedTotalAmount} />
      <styles.Label>
        <span>
          <Translate
            value="Sale.ProgressPercent"
            interpolation={{
              progress,
            }}
          />
        </span>
        <span>
          <Translate
            value="Sale.ProgressValue"
            interpolation={{
              formattedCollectedAmount,
              formattedTotalAmount,
              currency,
            }}
          />
        </span>
      </styles.Label>
    </styles.AmountBlock>
  );
}
