export enum ESaleType {
  /// First buyers receive tokens. Sale stops when collected_amount reached
  ByAmount = "ByAmount",
  /// Unlimited purchase, proportional distribution. Sale stops when end_date reached
  BySubscription = "BySubscription",
  /// It is for cancelled sales. Users can claim their tokens back.
  Cancelled = "Cancelled",
}

export interface VestingOutput {
  steps: number;
  quota: number;
  date: number;
}

interface SaleMetadata {
  /// Project name that is going to be on sale.
  name: string;
  /// Symbol (ticker) for the token on sale.
  symbol: string;
  /// Project description.
  description: string;
  /// Link to project smart contract.
  smart_contract_url: string;
  /// Project logo.
  logo_url: string;
  /// Symbol for output token
  output_ticker: string;
  /// Social medias of the project
  project_telegram: string | null;
  project_medium: string | null;
  project_twitter: string | null;
  reward_timestamp: number | null;
  reward_description: string | null;
}

export interface SaleOutput {
  sale_id: number;
  metadata: SaleMetadata;
  staking_contracts: Array<string>;
  min_near_deposit: string;
  deposit_token_id: string;
  claim_available: boolean;
  refund_available: boolean;
  distribute_token_id: string | null;
  distribute_token_decimals: number | null;
  min_buy: string;
  max_buy: string;
  max_amount: string;
  start_date: string;
  end_date: string;
  price: string;
  whitelist_hash: number[] | null;
  limit_per_transaction: string;
  collected_amount: string;
  num_account_sales: number;
  sale_type: ESaleType;
  claim_begun: boolean;
  vesting: Array<VestingOutput> | null;
}

export interface ISaleAccount {
  amount: string;
  amount_to_claim: string;
  claimed: string;
  refund: string;
  refunded: string;
}
export type FutureSaleProps = [number, FutureSaleOutput];

export interface FutureSaleOutput {
  metadata: SaleMetadata;
  staking_contracts: Array<string> | null;
  min_near_deposit: string | null;
  deposit_token_id: string | null;
  distribute_token_id: string | null;
  distribute_token_decimals: number | null;
  min_buy: string | null;
  max_buy: string | null;
  max_amount: string | null;
  start_date: string | null;
  end_date: string | null;
  price: string | null;
  whitelist_hash: Array<string> | null;
  limit_per_transaction: string | null;
  sale_type: ESaleType | null;
  vesting: Array<VestingOutput> | null;
}
