import { FungibleTokenContract } from "services/contract";
import { ITokenMetadata } from "services/interfaces";
import { IRPCProviderService } from "services/RPCProviderService/interfaces";
import { ZERO } from "shared/constants";
import { IBalance } from "shared/interfaces";
import { isNotNullOrUndefined, toMap } from "shared/utils";

export async function retrieveTokensMetadata(tokenAddresses: Array<string>, provider: IRPCProviderService) {
  const tokensMetadata: Array<FungibleTokenContract | null> = await Promise.all(
    tokenAddresses.map(async (address: string) => {
      const ftTokenContract: FungibleTokenContract = new FungibleTokenContract({
        provider,
        contractId: address,
      });
      const metadata = await ftTokenContract.getMetadata();
      if (!metadata) return null;
      return ftTokenContract;
    })
  );
  const tokensMetadataFiltered = tokensMetadata.filter(isNotNullOrUndefined);
  return toMap(tokensMetadataFiltered, "contractId");
}

export const createBalanceState = (contractId: string, metadata: ITokenMetadata, balance: string): IBalance => ({
  address: contractId,
  balance,
  decimal: metadata.decimals,
  symbol: metadata.symbol,
});

export async function retrieveBalances(tokens: { [key: string]: FungibleTokenContract }, accountId: string) {
  const balancesArray: Array<IBalance | null> = await Promise.all(
    Object.values(tokens).map(async (token) => {
      const metadata = token.metadata;
      if (!metadata) return null;
      const balance = await token.getBalanceOf({ accountId });
      return createBalanceState(token.contractId, metadata, balance);
    })
  );
  const filteredBalancesArray = balancesArray.filter(isNotNullOrUndefined);
  return toMap(filteredBalancesArray, "address");
}

export const getToken = (id: string, tokens: { [key: string]: FungibleTokenContract }): FungibleTokenContract | null =>
  tokens[id] || null;

export const getTokenBalance = (tokenId: string, balances: { [key: string]: IBalance }): string =>
  tokenId ? balances[tokenId]?.balance ?? ZERO : ZERO;
