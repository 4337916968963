import styled from "styled-components";

import { ReactComponent as BocaChica } from "assets/images/boca-logo.svg";
import { ReactComponent as WalletLogo } from "assets/images/icons/wallet.svg";
import Buttons from "shared/components/Buttons";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem 2.5rem;
  z-index: 11;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    align-self: center;
    justify-content: space-between;
    padding: 1.5rem 1rem 1.5rem;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 1.25rem;
    & > div:nth-child(2) { 
      grid-area: 2 / 1 / 3 / 3;
      justify-content: center;
    }
  `}
`;

const LeftRow = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-area: 1 / 1 / 2 / 2;
  `}
`;

const BocaChicaLogo = styled.div<{ isLoading?: boolean; isOpened: boolean }>`
  transition: opacity 0.3s;
  opacity: ${({ isLoading }) => (isLoading ? "0" : "1")};
  visibility: ${({ isOpened }) => (!isOpened ? "visible" : "hidden")};
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-area: 1 / 2 / 2 / 3; 
  `}
`;

const ButtonConnect = styled(Buttons.ButtonConnect)<{ isOpened: boolean }>`
  display: ${({ isOpened }) => (isOpened ? "none" : "flex")};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: flex-end;
    grid-area: 1 / 2 / 2 / 3; 
  `}
`;

export default {
  Container,
  LeftRow,
  WalletLogo,
  BocaChicaLogo,
  BocaChica,
  ButtonConnect,
};
