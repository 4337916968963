import { ITranslationKeys } from "services/translation";
import { EDimensions } from "shared/constants";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { IParseDate } from "shared/interfaces";
import { parseDate } from "shared/utils";

import SaleDataStyles from "./styles";
import Translate from "../Translate";

interface ISaleDate {
  startDate: number;
  endDate: number;
}

interface IDate {
  dimension: EDimensions;
  value: ITranslationKeys;
  date: IParseDate;
}

function GenerateDate({ dimension, date, value }: IDate) {
  const { year, month, day, hours, minutes } = date;

  switch (dimension) {
    case EDimensions.SMALL:
      return (
        <div>
          <span>
            <Translate value={value} />
          </span>
          <p>
            <Translate
              value="Sale.ShortDate"
              interpolation={{
                month,
                day,
                year,
              }}
            />
          </p>
          <p>
            <Translate
              value="Sale.Time"
              interpolation={{
                hours,
                minutes,
              }}
            />
          </p>
        </div>
      );
    default:
      return (
        <div>
          <span>
            <Translate value={value} />
          </span>
          <p>
            <Translate
              value="Sale.LongDate"
              interpolation={{
                month,
                day,
                year,
                hours,
                minutes,
              }}
            />
          </p>
        </div>
      );
  }
}

export default function SaleDate({ startDate, endDate }: ISaleDate) {
  const dimension = useWindowDimensions();
  const parseStartDate = parseDate(new Date(startDate).toString());
  const parseEndDate = parseDate(new Date(endDate).toString());
  return (
    <SaleDataStyles.DeadlineWrapper>
      <GenerateDate dimension={dimension} date={parseStartDate} value="Sale.StartDate" />
      <GenerateDate dimension={dimension} date={parseEndDate} value="Sale.FinishDate" />
    </SaleDataStyles.DeadlineWrapper>
  );
}
